import React, { useEffect, useRef, useState } from 'react';
import './index.scss'
import ModalView from '../ModalView';
import PrimaryButton from '../CustomButtons';
import { IoIosClose } from "react-icons/io";
import { fetchData } from "utils/fetch";
import { HOST, restAPIs } from "utils/restAPIs"
import { useSelector } from 'react-redux';
import { useSnackbar } from '../SnackbarProvider';
import { WriteOnBoard2 } from '../../../pages/Feed/styles';
import PhotoPicker from '../PhotoPicker';
import URLInput from '../URLInput';
import AttachFiles from '../AttachFiles';
import { Badge } from '@mui/material';
import axios from 'axios';
import CommonDeleteModal from '../CommonDeleteModal';
import PostPhotoScroller from '../PostPhotoScroller';
import { useNavigate } from 'react-router-dom';

interface Props {
  handleClose?: () => void;
  open: any;
  modalStyle?: any;
  handleOpen?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  createType?: string;
  editItem?: any;
  setFeed?: React.Dispatch<React.SetStateAction<any>>
  feed?: any;
  selectedIndex: number | null
}

interface FileItem {
  file: File;
  type: string;
}

interface SubjectChange {
  target: {
    value: string;
  };
}

const CreatePostEditModal: React.FC<Props> = ({ open, handleClose, editItem, setFeed, feed, selectedIndex }) => {

  const { showSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [post, setPost] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { activeClassroom } = useSelector((state: any) => state.classRoom) || {};
  const { user } = useSelector((state: any) => state.user) || {};
  const [imageFile, setImageFile] = useState<any>([]);
  const [percentage, setPercentage] = useState(0);
  const [inputVisible, setInputVisible] = useState<boolean>(false);
  const [files, setFiles] = useState<FileItem[]>([])
  const [urls, setUrls] = useState<string[]>([]);
  const [deleteModal, setDeleteModal] = useState<boolean>(false)
  const fileAttachmentRef = useRef<HTMLInputElement>(null);

  const handlePostChange = (event: SubjectChange) => {
    setPost(event.target.value);
    setError('')
  }

  useEffect(() => {
    if (editItem) {
      setUrls(editItem.externalInfo)
      setPost(editItem.content)
      let tempImage: any[] = [];
      let tempDoc: any[] = [];
      if (editItem?.documents?.length > 0) {
        editItem?.documents?.forEach(async (e: any) => {
          if (e.type?.startsWith('image/')) {
            tempImage.push(e);
          }
          else {
            tempDoc.push(e)
          }
        });
        setImageFile(tempImage)
        setFiles(tempDoc)
      }
    }
  }, [editItem]);

  function fileToPlainObject(file: any) {
    return {
      name: file.name,
      url: file.temp_url,
      lastModified: file.lastModified,
      lastModifiedDate: file.lastModifiedDate,
      size: file.size,
      type: file.type,
      webkitRelativePath: file.webkitRelativePath
    };
  }

  const onSubmit = async () => {
    const filesArray = files.map(obj => obj.file ?? obj);
    const uploadedData = [...imageFile, ...filesArray]
    const plainObjectArray = uploadedData?.map(file => {
      if (!file?.url) {
        return file && fileToPlainObject(file)
      }
      else {
        return file
      }
    });
    setIsLoading(true);
    let params = {
      classRoomId: activeClassroom && activeClassroom._id,
      userId: user._id,
      active: true,
      content: post,
      documents: plainObjectArray,
      externalInfo: urls,
    };
    const res = await fetchData(restAPIs.getEditPost(editItem?._id, params),navigate)
    setIsLoading(false);
    onClose()
    if (res.status == 200 && res.data && selectedIndex != null) {
      let temp_res = res.data
      temp_res.userId = {
        firstName: user?.firstName,
        lastName: user?.lastName,
        _id: user?._id
      }
      let temp = feed
      temp[selectedIndex] = temp_res
      setFeed?.(temp)
      showSnackbar('Post Updated', 'success')
    }
    else {
      showSnackbar('Something went wrong', 'error')
    }
  };

  const onClose = () => {
    handleClose?.()
    setPost('')
    setError('')
    setFiles([])
    setImageFile([])
    setDeleteModal(false)
  }

  async function onUpload() {
    const filesArray = files.map(obj => obj.file ?? obj);
    const uploadedData = [...imageFile, ...filesArray]
    setPercentage(0)
    if (uploadedData?.length > 0) {
      setIsLoading(true);
      let uploadedData = imageFile;
      for (var i = 0; i < imageFile.length; i++) {
        if (!imageFile[i]?.url) {
          let id = activeClassroom?._id;
          let formData = new FormData();
          formData.append('file', imageFile[i]);
          const config = {
            headers: {
              Authorization: localStorage.getItem('sessiontoken'),
            },
            onUploadProgress: (progressEvent: any) => {
              if (progressEvent.loaded > 100) {
                setPercentage(
                  (i / imageFile.length +
                    progressEvent.loaded /
                    progressEvent.total /
                    imageFile.length) *
                  99,
                );
              }
            },
          };
          await axios
            .post(HOST + 'misc/s3-upload?path=' + `classroom/${id}/post/${imageFile[i].name}`, formData, config)
            .then((response: any) => {
              if (response?.data?.status == 200 && response?.data?.data != null) {
                let temp = [...imageFile]
                temp[i].temp_url = `classroom/${id}/post/${imageFile[i].name}`;
                setImageFile(temp)
              } else {
                showSnackbar(response?.message ?? 'Something went wrong. Please try later', 'error')
              }
            })
            .catch((err: any) => {
              showSnackbar(err?.response?.data?.message
                ? err?.response?.data?.message
                : 'Something went wrong. Please try later', 'error')
            });
        }
      }
      if (i == imageFile.length) {
        onSubmit()
      }
    }
    else{
      onSubmit()
    }
  }


  const RenderModal = () => (
    <div className="create-post-modal">
      <div className='__header'>
        <h4>{editItem ? 'Edit' : 'Create'} Post</h4>
        <button onClick={() => setDeleteModal(true)}><IoIosClose /></button>
      </div>
      <div className='__oute_body_wrap'>
        <div className='body'>
          <WriteOnBoard2>
            <textarea value={post} onChange={handlePostChange} placeholder='Write On Board…' className='post-text-area'></textarea>
          </WriteOnBoard2>

          {imageFile?.length > 0 &&
            <PostPhotoScroller editItem={editItem} setImageFile={setImageFile} imageFile={imageFile} />
          }
        </div>
        <URLInput urls={urls} setUrls={setUrls} inputVisible={inputVisible} />
        <AttachFiles fileInputRef={fileAttachmentRef} editItem={editItem} setFiles={setFiles} imageFile={imageFile} files={files} />
      </div>
      <div className='__buttons2'>
        <PhotoPicker editItem={editItem} files={files} imageFile={imageFile} setImageFile={setImageFile} />
        <i onClick={() => setInputVisible(!inputVisible)} className="fi fi-rr-link"></i>
        <Badge
          sx={{
            '& .MuiBadge-badge': {
              color: '#fff',
              fontWeight: 400
            },
          }}
          onClick={() => fileAttachmentRef.current?.click()}
          badgeContent={files?.length ? files?.length > 9 ? '9+' : files?.length : 0}
          color="primary">
          <i className="fi fi-rr-clip"></i>
        </Badge>
        <PrimaryButton
          fullWidth={false}
          disabled={post?.length === 0 && imageFile?.length == 0 && files?.length === 0 && urls?.length === 0}
          progress={percentage}
          isLoading={isLoading} onClick={onUpload}>POST</PrimaryButton>
      </div>
    </div>
  )
  return (
    <ModalView open={open} modalStyle={{ padding: 0 }}>
      {RenderModal()}
      <CommonDeleteModal
        open={deleteModal}
        buttonLabel={'Yes, Discard & close'}
        description={`This will discard all your changes. Confirm discard?`}
        title={'Discard Changes'}
        handleClose={() => setDeleteModal(false)}
        handleSubmit={() => onClose()}
      />
    </ModalView>
  );
};

export default CreatePostEditModal;