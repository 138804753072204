import { buildGetEndpoint } from "./commonUtils";
export const HOST = "https://api.classteam.io/api/";
// export const HOST = "https://stgapi.classteam.io/api/";
// export const HOST ='https://stagingapi.classteam.io/api/';
export const FEEDS = HOST + 'post';
export const S3UPLOAD = HOST + 'misc/s3-upload?path=';

export const restAPIs = {

  getSignIn: (body: any) => ({
    method: 'post',
    body,
    endpoint: `${HOST}auth/login`
  }),
  getSignUp: (body: any) => ({
    method: 'post',
    body,
    endpoint: `${HOST}auth/signup`
  }),
  getPhoneOtp: (body: any) => ({
    method: 'post',
    body,
    endpoint: `${HOST}auth/send-phone-otp`
  }),
  getLoginOtp: (body: any) => ({
    method: 'post',
    body,
    endpoint: `${HOST}auth/login-otp`
  }),
  getOtpVerify: (otp: any,) => ({
    method: 'get',
    endpoint: `${HOST}auth/verify?token=${otp}`
  }),
  putUserDetails: (body: any,) => ({
    method: 'put',
    body,
    endpoint: `${HOST}user`
  }),
  getUserImage : (id:any,formData: any,) => ({
    method: 'post',
    formData,
    endpoint: `${HOST}misc/s3-upload?path=user/${id}/user-image`
  }),
  getClassRoomList: (body: any) => ({
    method: 'get',
    endpoint: buildGetEndpoint(`${HOST}class-room/suggest`,body)
  }),
  getClassMetadata: (id: any) => ({
    method: 'get',
    endpoint: `${HOST}class-room/${id}/stats`
  }),
  getClassRoom: (id: any,body:any) => ({
    method: 'post',
    body,
    endpoint: `${HOST}class-room/${id}/join`
  }),
  getNotes: (body: any) => ({
    method: 'get',
    endpoint: buildGetEndpoint(`${HOST}note/list`,body)
  }),
  getNotesDelete: (id: any) => ({
    method: 'delete',
    endpoint:`${HOST}note/${id}`
  }),
  getSubjects: (id: any) => ({
    method: 'get',
    endpoint: `${HOST}subject/${id}/list`
  }),
  getSubjectsDelete: (id: any) => ({
    method: 'delete',
    endpoint: `${HOST}subject/${id}`
  }),
  getCurriculum: (body: any) => ({
    method: 'get',
    endpoint: buildGetEndpoint(`${HOST}curriculum`,body)
  }),
  getTodaysEvents: (id: any,body: any) => ({
    method: 'get',
    endpoint: buildGetEndpoint(`${HOST}event/${id}/list`,body)
  }),
  getEvents: (id: any,body: any) => ({
    method: 'get',
    endpoint: buildGetEndpoint(`${HOST}event/${id}/list`,body)
  }),
  getEventsDelete: (id: any) => ({
    method: 'delete',
    endpoint:`${HOST}event/${id}`
  }),
  getDocuments: (body: any) => ({
    method: 'get',
    endpoint: buildGetEndpoint(`${HOST}document/list`,body)
  }),
  getupdateDocuments: (id: any,body: any) => ({
    method: 'put',
    body,
    endpoint: `${HOST}document/${id}`
  }),
  getDocumentsDelete: (id: any) => ({
    method: 'delete',
    endpoint:`${HOST}document/${id}`
  }),
  getActiveClassDetails: (id: any) => ({
    method: 'get',
    endpoint: `${HOST}class-room/${id}`
  }),
  getMetaData: (id: any) => ({
    method: 'get',
    endpoint: `${HOST}class-room/${id}/widget?period=month`
  }),
  getFeed: (body: any) => ({
    method: 'get',
    endpoint: buildGetEndpoint(`${HOST}post/list`,body)
  }),
  getFeedDetails: (id: any) => ({
    method: 'get',
    endpoint: `${HOST}post/${id}`
  }),
  getAddNote: (body: any) => ({
    method: 'post',
    body,
    endpoint: `${HOST}note`
  }),
  getNoteItem: (id: string) => ({
    method: 'get',
    endpoint: `${HOST}note/${id}`
  }),
  getupdateNote: (id: any,body: any) => ({
    method: 'put',
    body,
    endpoint: `${HOST}note/${id}`
  }),
  getAddDocument: (body: any) => ({
    method: 'post',
    body,
    endpoint: `${HOST}document`
  }),
  getDocumentItem: (id: string) => ({
    method: 'get',
    endpoint: `${HOST}document/${id}`
  }),
  getAddSubject: (body: any) => ({
    method: 'post',
    body,
    endpoint: `${HOST}subject`
  }),
  getAddCurriculum: (body: any) => ({
    method: 'post',
    body,
    endpoint: `${HOST}curriculum`
  }),
  getupdateCurriculum: (id: any,body: any) => ({
    method: 'put',
    body,
    endpoint: `${HOST}curriculum/${id}`
  }),
  getCurriculumDelete: (id: any,body: any) => ({
    method: 'put',
    body,
    endpoint: `${HOST}curriculum/${id}`
  }),
  getupdateSubject: (id: any,body: any) => ({
    method: 'put',
    body,
    endpoint: `${HOST}subject/${id}`
  }),
  getAddEvents: (body: any) => ({
    method: 'post',
    body,
    endpoint: `${HOST}event`
  }),
  getupdateEvents: (id: any,body: any) => ({
    method: 'put',
    body,
    endpoint: `${HOST}event/${id}`
  }),
  getS3Delete: (url: string) => ({
    method: 'post',
    endpoint: `${HOST}misc/s3-delete?path='${url}`
  }),
  getPostVote: (id: string,type: string) => ({
    method: 'post',
    endpoint: `${HOST}post/${id}/${type}`
  }),
  getComments: (id: any,body: any) => ({
    method: 'get',
    endpoint:buildGetEndpoint(`${HOST}comment/list/${id}`,body) 
  }),
  getAddComments: (body: any) => ({
    method: 'post',
    body,
    endpoint:`${HOST}comment` 
  }),
  getDeleteComments:(id: any) => ({
    method: 'delete',
    endpoint:`${HOST}comment/${id}`
  }),
  getupdateComments: (id: any,body: any) => ({
    method: 'put',
    body,
    endpoint: `${HOST}comment/${id}`
  }),

  updateTimetable: (id: any, body: any) => ({
    method: 'put',
    body,
    endpoint: `${HOST}class-room/${id}/timetable`
  }),
  getDeletePost:(id: any) => ({
    method: 'delete',
    endpoint:`${HOST}post/${id}`
  }),
  deactivateAccount:(body: any) => ({
    method: 'post',
    body,
    endpoint:`${HOST}user/deactivate`
  }),

  getMyActivities: (body: any) => ({
    method: 'get',
    endpoint: buildGetEndpoint(`${HOST}activity`,body)
  }),

  reportProblem:(body: any) => ({
    method: 'post',
    body,
    endpoint:`${HOST}issue`
  }),


  getEditPost: (id: any,body: any) => ({
    method: 'put',
    body,
    endpoint: `${HOST}post/${id}`
  }),
  getReportReason: () => ({
    method: 'get',
    endpoint: `${HOST}issue/report-types`
  }),
  getReportPost: (id:string,body:any) => ({
    method: 'put',
    body,
    endpoint: `${HOST}post/${id}/report`
  }),

  getUserList:(body: any) => ({
    method: 'post',
    body,
    endpoint:`${HOST}user/list`
  }),
  getForgotPassword: (body: any) => ({
    method: 'post',
    body,
    endpoint: `${HOST}auth/forgot-password`
  }),
  getForgotPasswordOtp: (body: any) => ({
    method: 'post',
    body,
    endpoint: `${HOST}auth/verify-password-reset-token`
  }),
  getEmailRetryOtp: (body: any) => ({
    method: 'get',
    endpoint: buildGetEndpoint(`${HOST}auth/retry-verify`,body) 
  }),
  getResetPasswordOtp: (body: any) => ({
    method: 'post',
    body,
    endpoint: `${HOST}auth/reset-password`
  }),
  getUserPicUpload: (id:string,name:string,body: any) => ({
    method: 'post',
    body,
    endpoint: `${S3UPLOAD}user/${id}/${name}`
  }),
  getUserUpdate: (method:string,body?: any) => ({
    method: method,
    body,
    endpoint: `${HOST}user`
  }),

  getNotificationList: (body:any) => ({
    method: 'get',
    endpoint: buildGetEndpoint(`${HOST}notification`,body) 
  }),
  getVerifyClassRoom: (body: any) => ({
    method: 'get',
    endpoint:buildGetEndpoint(`${HOST}class-room/check`,body)
  }),
  getTemplateFeed: (body: any) => ({
    method: 'get',
    endpoint: buildGetEndpoint(`${HOST}filter/suggest`,body)
  }),
  getBoardTemplate: (body: any) => ({
    method: 'get',
    endpoint: buildGetEndpoint(`${HOST}template/suggest`,body)
  }),
  getClassRoomAdd: (body: any) => ({
    method: 'post',
    body,
    endpoint: `${HOST}class-room`
  }),
  getClassRoomPrivacy: (id: any,body: any) => ({
    method: 'put',
    body,
    endpoint: `${HOST}class-room/${id}`
  }),

};
