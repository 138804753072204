export const NOTE_TITLE = 150;
export const DOCUMENT_TITLE = 150;
export const EVENT_TITLE = 150;
export const SUBJECT_TITLE = 150;
export const SESSION_TITLE = 150;
export const CLASSNAME = 25;
export const CLASSID = 10;
export const FIRST_NAME_LENGTH = 32
export const LAST_NAME_LENGTH = 32
export const CHAT_NAME_LENGTH = 32

export const GROUP_NAME_LENGTH = 64;
export const CLASSROOM_BIO_LENGTH = 250;
export const LINK_LENGTH = 2000;

export const fileType = ['pdf', 'video', 'doc', 'img', 'file']

export const NOTIFICATION_MODULES = {
  document: 'DOCUMENT',
  note: 'NOTE',
  event: 'EVENT',
  timetable: 'TIMETABLE',
  curriculum: 'CURRICULUM',
  subject: 'SUBJECT',
  userRemoval: 'USER_REMOVAL',
  userApproval: 'USER_APPROVAL',
  userAdmin: 'USER_ADMIN',
  user: 'USER',
  post: 'POST',
};

export const CREATE_CLASS_FOOTER_TEXT = 'This name will be displayed to your classmates on joining. you can change the name in future.';
export const CREATE_CLASS_ID_FOOTER_TEXT = 'This ID will be displayed to your classmates on joining. You cannot change the ID in the future.';
export const TEMPLATE_INFO_TEXT = 'Now create classroom from University / Board template and get recent updates.';
export const SET_PRIVACY_PUBLIC_DESC = 'Anyone can join this classroom';
export const SET_PRIVACY_PRIVATE_DESC = 'Only people given access can join';
export const SET_PRIVACY_TOOGLE_INFO_TITLE = 'Get latest news and updates';
export const SET_PRIVACY_TOOGLE_INFO = 'Keep updated with latest news and current affairs from your interest of study';
export const SET_PRIVACY_IMAGE_PICKER = [
  {
    title: 'Add Classroom Logo',
    data: { title: 'Upload a photo', desc: 'You can either upload photo or take it now' }
  },
  {
    title: 'Add Classroom Cover',
    data: { title: 'Upload a photo', desc: 'You can either upload photo or take it now' }
  }
];
export const SET_PRIVACY_CLASSROOM_DESC = 'Write about your classroom…';
