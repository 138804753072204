import 'swiper/css';
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import React, { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import './index.scss'
import { useSelector } from 'react-redux';
import { fetchData } from "utils/fetch";
import { restAPIs } from "utils/restAPIs"
import { useNavigate } from 'react-router-dom';

const QuickAccess = () => {

    const navigate = useNavigate()
    const [metadata, setMetadata] = useState<any>(null);
    const [isLoading, setLoading] = useState<any>(false);
    const { activeClassroom } = useSelector((state: any) => state.classRoom) || {};

    useEffect(() => {
        fetchNotes();
    }, []);

    const fetchNotes = async () => {
        if (activeClassroom) {
            setLoading(true)
            const res = await fetchData(restAPIs.getMetaData(activeClassroom?._id),navigate)
            if (res.status === 200 && res.data) {
                setMetadata(res?.data)
            }
            setLoading(false)
        }
    }

    const data = [
        {
            iconName: 'fi fi-rr-memo-pad',
            title: 'Notes',
            subTitle: metadata?.notes?.notesCount ?? '0',
            screen: '/notes',
        },
        {
            iconName: 'fi fi-rr-duplicate',
            title: 'Documents',
            subTitle: metadata?.documents?.documentsCount ?? '0',
            screen: '/documents',
        },
        {
            iconName: 'fi fi-rr-book-copy',
            title: 'Subjects',
            subTitle: metadata?.subjects?.subjectsCount ?? '0',
            screen: '/subjects',
        },
        {
            iconName: 'fi fi-rr-book-alt',
            title: 'Curriculum',
            subTitle: metadata?.curriculum?.curriculumCount ?? '0',
            screen: '/curriculum',
        },
        {
            iconName: 'fi fi-rr-party-horn',
            title: 'Events',
            subTitle: metadata?.events?.eventsCount ?? '0',
            screen: '/events',
        },
    ];
    return (
        <div style={{ padding: '0 20px' }}>
            <Typography style={style} variant="h3" className='home-form-title' fontSize={15}>
                My Classroom
            </Typography>
            <div className='card-container'>
                {data.map((item) => (
                    <div onClick={() => navigate(item?.screen)} key={item.title} className="quick-access-card">
                        <div className="quick-access-img-container">
                            <i className={item?.iconName}></i>
                            <Typography fontWeight={'normal'} variant='h6'>{item.title}</Typography>
                        </div>
                        <div className="card-content">
                            <Typography variant='h1' fontSize={24}>{isLoading ? '-' : item.subTitle}</Typography>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default QuickAccess;

const style = {
    marginBottom: '15px'
}

