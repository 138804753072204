import React, { useState } from 'react';
import ModalView from '../ModalView';
import { IoIosClose } from "react-icons/io";
import './index.scss'
import PrimaryButton from '../CustomButtons';
import { useLogout } from 'utils/customHooks';
import { fetchData } from 'utils/fetch';
import { restAPIs } from 'utils/restAPIs';
import { useNavigate } from 'react-router-dom';

interface Props {
    handleClose?: () => void;
    open: any;
    modalStyle?: any;
}

const DeleteModal: React.FC<Props> = ({ open, handleClose, modalStyle }) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const logout = useLogout()
    const navigate = useNavigate();

    const onClose = () => {
        handleClose?.()
    }

    const handleSubmit = async () => {
        setIsLoading(true)
        let params = {};
        const res = await fetchData(restAPIs.deactivateAccount(params),navigate)
        setIsLoading(false)
        if (res.status === 200 && res.data) {
            logout();
        }
    }

    const RenderModal = () => (
        <div className="create-logout-modal">
            <div className='__header'>
                <h4>Delete Account</h4>
                <button onClick={handleClose}><IoIosClose /></button>
            </div>
            <div className="__logout-content">
                <p>Are you sure want to delete account?</p>
            </div>
            <div className='modal-footer-button ___btn'>
                <PrimaryButton
                    fullWidth={false}
                    onClick={() => handleSubmit()}
                    isLoading={isLoading}>
                    Yes, I'am Sure!
                </PrimaryButton>
            </div>
        </div>
    )
    return (
        <ModalView open={open} handleClose={onClose} modalStyle={modalStyle}>
            {RenderModal()}
        </ModalView>
    )
};

export default DeleteModal;
