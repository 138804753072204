import React, { useState, useEffect } from 'react';
import GridContainer from '../../components/molecules/GridContainer';
import UserCard from '../../components/atoms/UserCard';
import { Typography } from '@mui/material';
import './index.scss'
import NoteFiles from '../../components/atoms/NoteFiles';
import NoNotes from '../../components/atoms/NoNotes';
import { useNavigate, useParams } from 'react-router-dom';
import _ from 'lodash';
import Tooltip from '@mui/material/Tooltip';
import { useFetchClassRoomItems } from 'utils/customHooks';
import { colors } from 'assets/styles/colors';
import CircularProgress from '@mui/material/CircularProgress';
import CreateNoteModal from '../../components/atoms/CreateNoteModal';
import { fetchData } from "utils/fetch";
import { restAPIs } from "utils/restAPIs"
import { useSnackbar } from '../../components/atoms/SnackbarProvider';
import CommonDeleteModal from '../../components/atoms/CommonDeleteModal';
import { useSelector } from 'react-redux';

const Documents: React.FC = () => {

    const { showSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const { user } = useSelector((state: any) => state.user) || {};
    const [selected, setSelected] = useState<number>(0)
    const [documentList, setDocumentList] = useState<any>([]);
    const { subject, refId='' } = useParams()
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [createType, setCreateType] = useState<any>('');
    const [editItem, setEditItem] = useState<any>(null);
    const [deleteModal, setDeleteModal] = useState<boolean>(false)
    const { fetchDocuments, fetchMetaData } = useFetchClassRoomItems()

    const isOwned = user?._id == documentList?.[0]?.userId?._id || user?._id == documentList?.[0]?.createdBy?._id

    const loadMore = async (initialPage?: boolean) => {
            setIsLoading(true)
            const res = await fetchData(restAPIs.getDocumentItem(refId),navigate)
            setIsLoading(false)
            if (res?.status === 200 && res?.data) {
                if (refId) {
                    setDocumentList([res.data])
                }
            }
    }

    useEffect(() => {
        loadMore()
    }, [])

    const onEdit=()=>{
        setCreateType('Document')
    }

    useEffect(() => {
        setEditItem(documentList[selected])
    }, [documentList])

    const onDeleteItem=()=>{
        setDeleteModal(true)
    }

    const onDelete = async () => {
        setIsLoading(true)
        setDeleteModal(false)
        const res = await fetchData(restAPIs.getDocumentsDelete(documentList?.[selected]?._id),navigate)
        setIsLoading(false)
        if (res.status == 200) {
            showSnackbar('Document deleted successfully', 'success')
            let temp = [...documentList]
            temp.splice(selected, 1)
            setDocumentList(temp)
            fetchDocuments()
            fetchMetaData()
            navigate(-1)
        }
        else {
            showSnackbar('something went wrong !', 'error')
        }
    }

    return (
        <GridContainer
            pageTitle={_.capitalize(documentList?.[0]?.title)}
            data={documentList}
            backButton={true}
            onEdit={onEdit}
            onDelete={onDeleteItem}
            optionsVisible={isOwned}
            leftGridPartition={1}>
            {isLoading &&
                <div style={{ marginBottom: '10px' }} className='loader-container'>
                    <CircularProgress size={15} sx={{ color: colors?.primary }} />
                </div>}
            <div style={{ marginBottom: '20vh' }}>
                {documentList?.map((item: any, index: number) => {
                    const fileCount = item?.files?.length
                    return (
                        <div onClick={() => setSelected(index)} className={`notes-container fullscreen`}>
                            <Typography variant='h6' fontWeight={400} style={{ wordWrap: 'break-word' }} fontSize={'12px'} key={index}>
                                {item.description}
                            </Typography>
                            {item?.subjectId?.name?.length > 0&&<div>Subject:</div>}
                            <div className='notes-sub-container'>
                                {item?.subjectId?.name?.length > 0 &&
                                    <Tooltip title={item?.subjectId?.name}>
                                        <div className='notes-sub-name'>
                                            {item?.subjectId?.name}
                                        </div></Tooltip>}
                            </div>
                            <div>Created by:</div>
                            <div className="notes-user-card-wrapper">
                                <UserCard height={32} width={32} item={item} h1FontSize={'11px'} pFontSize={'10px'} />
                            </div>
                            <div className='notes-count-name-text'>
                                    {fileCount} {fileCount == 1 ? ' File' : ' Files'}
                                </div>
                            <div  >
                                <NoteFiles fullScreen={true} detailRefresh={loadMore} notes={documentList[selected]} setNotes={setDocumentList} selected={selected} notesArray={documentList} type='Documents' />
                            </div>
                        </div>
                    );
                })}
                 <CreateNoteModal
                open={createType == 'Document'}
                editItem={editItem}
                createType={createType}
                modalStyle={{ padding: '0px' }}
                detailRefresh={loadMore}
                handleClose={()=>setCreateType('')} /> 
                  <CommonDeleteModal
                    open={deleteModal}
                    buttonLabel={'Yes, Delete'}
                    description={`This action cannot be undone. Are you sure you want to proceed?`}
                    title={'Delete Document'}
                    handleClose={() => setDeleteModal(false)}
                    handleSubmit={() => onDelete()}
                />
                {documentList?.length == 0 && !isLoading &&
                    <NoNotes type={'Documents unavailable'} />}
            </div>
        </GridContainer>
    )
};

export default Documents;
