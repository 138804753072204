import React, { useEffect, useState } from 'react';
import './index.scss';
import { useSelector } from 'react-redux';
import { fetchData } from 'utils/fetch';
import { restAPIs } from 'utils/restAPIs';
import { ProfileCard } from 'assets/styles/commonStyle';
import { Avatar, CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';


const ClassmatesListWrapper: React.FC<any> = () => {
    const navigate = useNavigate();
    const { user } = useSelector((state: any) => state.user) || {};
    const { activeClassroom } = useSelector((state: any) => state.classRoom) || {};
    const [isLoding, setLoading] = useState(false);
    const [list, setList] = useState<any>([])


    useEffect(() => {
        fetchUsers();
    }, []);


    const fetchUsers = async () => {
        setLoading(true)
        let array: any = activeClassroom?.members?.length > 0 && activeClassroom?.members;
        let temp_array: any = [];
        array.map((item: any) => {
            if (item.status == 'ACTIVE') {
                temp_array.push(item.userId);
            }
        });
        let params = temp_array;
        const response = await fetchData(restAPIs.getUserList(params), navigate);
        setLoading(false)
        if (response.status === 200 && response.data) {
            let userList = response?.data ? response.data : [];
            let sortedUserList = userList.sort((a: any, b: any) =>
                (a.firstName + a.lastName).localeCompare(b.firstName + b.lastName),
            );
            let filteredPeople = sortedUserList.filter(
                (item: any) => item._id !== user?._id,
            );
            setList(filteredPeople);
        }
    }

    return (
        <div className="__full_list">
            {isLoding && <CircularProgress size={'20px'} color="primary" />}
            {list?.map((item: any, index: number) => {
                return (
                    <div className='__card_d'>
                        <ProfileCard style={{ alignItems: 'center', gridTemplateColumns: '45px 1fr auto' }}>
                            <Avatar
                                src={item?.profilePic}
                                sx={{ bgcolor: '#575757', width: 34, height: 34, fontSize: '14px' }}
                                aria-label="recipe"
                            >
                                {item?.firstName?.slice(0, 1)}
                                {item?.lastName?.slice(0, 1)}
                            </Avatar>
                            <div className="___label">
                                <div className="name">
                                    <h1>{item?.firstName} {item?.lastName}</h1>
                                </div>
                            </div>
                            {activeClassroom?.owners.some((data: any) => data.userId == item._id) && <span className='__admin'>Admin</span>}
                        </ProfileCard>
                    </div>
                )
            })}
        </div>
    );
};

export default ClassmatesListWrapper;
