

import styled from "styled-components";

interface ProfileCardProps {
    h1FontSize?: string;
    pFontSize?: string;
  }

export const ProfileCard = styled.div<ProfileCardProps>`
    
    align-items: self-start;
    display: grid;
    grid-template-columns: 45px 1fr 20px;
    .___label{
    .name{
      display:flex;
      gap:5px;
      align-items: center;
      p{
            font-size: ${(props?:any) => props?.h1FontSize || '14px'};
      }
    }
      text-align: left;
        h1{
            font-size: ${(props?:any) => props?.h1FontSize || '14px'};
            margin: 0;
        }
        p{
            margin: 0;
            font-size: ${(props?:any) => props?.pFontSize || '11px'};
            color: #575757;
        }
        label{
            background: rgb(0 145 234 / 25%) 0% 0% no-repeat padding-box !important;
            color: #0091EA;
            padding: 1px 11px;
            border-radius: 30px;
            font-size: 13px;
            font-weight: 800;
        }
    }
    button{
      background-color: transparent;
      border: none;
      font-size: 20px;
      cursor: pointer;
    }
    
`
