import React from 'react';
import './index.scss';
import GridContainer from '../../components/molecules/GridContainer';
import {
  PeopleAltOutlined,
  SchoolOutlined,
  FilterNone,
  PermIdentity,
  ErrorOutlineOutlined,
  HelpOutlineRounded
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import ListWrapper from '../../components/molecules/ListWrapper';

const classroomSettings = ['Classmates', 'Subjects', 'My Activities'];
const accountSettings = ['Account', 'Report a Problem', 'Help & Support']
const data = [
  { title: 'Classroom Settings', settings: classroomSettings },
  { title: 'Account Settings', settings: accountSettings }
]
const Settings: React.FC = () => {

  const navigate = useNavigate();

  const getIcon = (item: string): JSX.Element => {
    const temp_Arr = [...classroomSettings, ...accountSettings]
    switch (item) {
      case temp_Arr[0]:
        return <PeopleAltOutlined className='Drawer-icon' sx={{ fontSize: 20 }} />;
      case temp_Arr[1]:
        return <SchoolOutlined className='Drawer-icon' sx={{ fontSize: 20 }} />;
      case temp_Arr[2]:
        return <FilterNone className='Drawer-icon' sx={{ fontSize: 20 }} />;
      case temp_Arr[3]:
        return <PermIdentity className='Drawer-icon' sx={{ fontSize: 20 }} />;
      case temp_Arr[4]:
        return <ErrorOutlineOutlined className='Drawer-icon' sx={{ fontSize: 20 }} />;
      case temp_Arr[5]:
        return <HelpOutlineRounded className='Drawer-icon' sx={{ fontSize: 20 }} />;
      default:
        return <div />;
    }
  }

  const convertString = (str: string): string => {
    if (!['My Activities', 'Account', 'Help & Support', 'Subjects', 'Classmates'].includes(str)) {
      return '/settings'
    }
    const formattedString = str.replace(/[^a-z0-9]+/gi, '').toLowerCase();
    return `/${formattedString}`;
  }

  const onClick = (setting: string) => {
    if (setting === 'Help & Support') {
      if (typeof window !== 'undefined' && window.open) {
        const newWindow = window.open('https://classteam.io/help-and-support.html', '_blank');
        newWindow?.focus();
      }
    }
    else if (setting === 'Report a Problem') {
      navigate('/report');
    }
    else {
      if (navigate && typeof navigate === 'function') {
        navigate(convertString(setting));
      }
    }
  };

  return (
    <GridContainer pageTitle={'Settings'} leftGridPartition={3 / 4}>
      <div className='__settings'>
        <ListWrapper data={data} getIcon={getIcon} onClick={onClick} />
      </div>
    </GridContainer>
  );
};

export default Settings;
