import React, { useEffect, useState } from 'react';
import './index.scss';
import { colors } from 'assets/styles/colors';
import NoNotes from '../NoNotes';
import _ from 'lodash';
import moment from 'moment-timezone';
import {  CircularProgress, Typography } from '@mui/material';
import { IoIosClose } from "react-icons/io";
import UserCard from '../UserCard';
import ModalView from '../ModalView';
import ListActionMenu from '../ListActionMenu';
import { fetchData } from "utils/fetch";
import { restAPIs } from "utils/restAPIs"
import { useFetchClassRoomItems } from 'utils/customHooks';
import { useSelector } from 'react-redux';
import CreateEventModal from '../CreateEventModal';
import { useSnackbar } from '../SnackbarProvider';
import { useNavigate } from 'react-router-dom';


interface CustomNoteFilesProps {
    event?: any;
    type?: string
    todaysEvent?: any;
    modalData?: any;
    setModal: React.Dispatch<React.SetStateAction<any>>;
}

const EventFiles: React.FC<CustomNoteFilesProps> = ({ event, todaysEvent, type = 'Notes', modalData,setModal }) => {

    const { showSnackbar } = useSnackbar();
    const navigate = useNavigate();
    // const [modalData, setModal] = useState<any>(null);
    const { classroomData, activeClassroom } = useSelector((state: any) => state.classRoom) || {};
    const { isEventLoading } = classroomData
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [createType, setCreateType] = useState<any>('');
    const [editItem, setEditItem] = useState<any>(null);
    const { fetchTodaysEvents, fetchEvents, fetchMetaData } = useFetchClassRoomItems()

    const filterEvents = (events: Event[], todayEvents: Event[]): Event[] => {
        const now = new Date();
        return _.differenceBy(events, todayEvents, JSON.stringify)?.filter((event:any) => new Date(event.endTime) > now);
    };

    const formatDate = (dateStr: string) => {
        const dateObj = moment.utc(dateStr);
        const year = dateObj.format('YYYY')
        const monthName = dateObj.format('MMM');
        const day = dateObj.format('DD');
        const time = dateObj.format('h:mm A');
        return { year, month: monthName, day, time };
    };
    const onDelete = async () => {
        setIsLoading(true)
        const res = await fetchData(restAPIs.getEventsDelete(modalData._id),navigate)
        if (res.status == 200) {
            showSnackbar('Event deleted successfully', 'success')
            fetchTodaysEvents(activeClassroom?._id)
            fetchMetaData()
            fetchEvents(activeClassroom?._id)
            setIsLoading(false)
            handleClose()
        }
        else {
            setIsLoading(false)
            showSnackbar('something went wrong !', 'error')
        }
    }
    // useEffect(() => {
    //     setModal(modalDataItem);
    // }, [modalDataItem]);
    const onEdit = () => {
        setEditItem(modalData)
        setCreateType('Events')
    }
    const handleClose = () => {
        setModal(null)
        setEditItem(null)
    }

    const renderModal = () => {
        const { year, month, day, time } = formatDate(modalData?.startTime)
        const endDate = formatDate(modalData?.endTime)
        const createdBy = { userId: modalData?.createdBy }
        return (
            <div className='event__custome_modal'>
                <div className='__header'>
                    <h4>{month?.toUpperCase()} {day}</h4>
                    <button onClick={handleClose}><IoIosClose /></button>
                </div>
                <div className='__modal_menu_list'>
                    <div className='__menu_single'>
                        <div style={{ marginTop: '0px' }} className="title-note-container">
                            <Typography color={'#575757'} variant='h4'>Title</Typography>
                            {isLoading ? <CircularProgress size={20} sx={{ color: colors?.primary }} /> :
                                <ListActionMenu item={modalData} onEdit={onEdit} onDelete={onDelete} />}
                        </div>
                        <Typography color={'#000'} fontSize={'13px'} variant='h3'>{modalData?.name}</Typography>
                    </div>
                    {modalData?.description?.length > 0 &&
                        <div className='__menu_single'>
                            <Typography color={'#575757'} variant='h4'>Description</Typography>
                            <Typography fontSize={'13px'} variant='h3'>{modalData?.description}</Typography>
                        </div>}
                    <div className='__menu_single'>
                        <Typography color={'#575757'} marginBottom={'5px'} variant='h4'>Created by</Typography>
                        <UserCard h1FontSize='12px' item={createdBy} />
                    </div>
                    <div className='__menu_single'>
                        <Typography color={'#575757'} variant='h4'>Timing</Typography>
                    </div>
                    <div className='timing-container'>
                        <Typography fontSize={'14px'} variant='body1'>Start Date</Typography>
                        <Typography className='timing-text' variant='body1'>{month} {day} {year} - {time}</Typography>
                    </div>
                    <div className='timing-container'>
                        <Typography fontSize={'14px'} variant='body1'>End Date</Typography>
                        <Typography className='timing-text' variant='body1'>{endDate?.month} {endDate?.day} {endDate?.year} - {endDate?.time}</Typography>
                    </div>
                </div>
            </div>
        )
    }

    const renderEvents = (title: string, events: any) => (
        <div className='event-wrapper'>
            {events?.length > 0 &&
                <Typography variant='h3'>{title}</Typography>}
            {events?.map((item: any, index: number) => {
                const { month, day, time } = formatDate(item?.startTime)
                return (
                    <div onClick={() => setModal(item)} className='event-card'>
                        <div >
                            <Typography variant='h4'>{day}</Typography>
                            <Typography color={colors?.normal} fontSize={'10px'} variant='h4'>{month}</Typography>
                        </div>
                        <div className='name'>
                            <Typography variant='h4'>{item?.name}</Typography>
                            {item?.description && <Typography color={colors?.normal} className='desc' fontSize={'10px'} variant='h4'>{item?.description}</Typography>}
                        </div>
                        <div >
                            <Typography variant='h4'>{time}</Typography>
                        </div>
                    </div>
                );
            })}
        </div>
    )

    return (
        <div style={{ marginTop: '15px', marginBottom: '20vh' }} className='note-files-wrapper'>
            {isEventLoading &&
                <div style={{ marginBottom: '10px' }} className='loader-container'>
                    <CircularProgress size={15} sx={{ color: colors?.primary }} />
                </div>}
            {todaysEvent?.length > 0 && renderEvents('Today', todaysEvent)}
            {event?.length > 0 && renderEvents('Upcoming', filterEvents(event, todaysEvent))}
            <ModalView open={modalData} handleClose={handleClose}>
                {renderModal()}
            </ModalView>
            {event?.length == 0 &&
                <NoNotes type={type} />}
            <CreateEventModal
                open={createType == 'Events'}
                editItem={editItem}
                eventDetailModalClose={handleClose}
                modalStyle={{ padding: '0px' }}
                createType={createType}
                handleClose={() => setCreateType('')} />
        </div>
    );
};

export default EventFiles;
