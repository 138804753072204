import React, { useState } from 'react';
import './index.scss';
import GridContainer from '../../components/molecules/GridContainer';
import { TextField } from '@mui/material';
import { CLASSNAME, CREATE_CLASS_FOOTER_TEXT } from 'utils/constants';
import PrimaryButton from 'components/atoms/CustomButtons';
import { useNavigate } from 'react-router-dom';

const ClassName: React.FC = () => {


    const navigate = useNavigate()
    const [error, setError] = useState<string | null>(null);
    const [name, setName] = useState<string>('');

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        if (value?.length < CLASSNAME) {
            setName(value);
            setError(null)
        }
        else {
            setError(`Maximum length allowed is ${CLASSNAME} characters`)
        }
    };

    const onSubmit = () => {
        navigate(`/class-id/${name?.trim()}`)
    }

    function getDesabled() {
        let desabled = false;
        if (name?.trim().length > 0) {
            if (name.length <= CLASSNAME) {
                desabled = false;
            } else {
                desabled = true;
            }
        } else {
            desabled = true;
        }
        return desabled;
    }

    return (
        <GridContainer
            pageTitle={'Create Class'}
            backButton
            leftGridPartition={3 / 4}
        >
            <form onSubmit={(e) => onSubmit}>
                <div className='_create_class_name'>
                    <h1 className='title'>Enter your classroom</h1>
                    <h1 className='__sub_title'>Name</h1>
                    <TextField
                        label="Classroom Name"
                        fullWidth
                        variant="outlined"
                        error={!!error}
                        helperText={error}
                        onChange={handleChange}
                        margin="normal"
                        name="firstName"
                        InputLabelProps={{
                            style: { fontSize: '13px', fontWeight: 400, color: "#B4B4B4" },
                        }}
                        className="input-field"
                        value={name}
                        style={{ marginBottom: '10px' }}
                    />
                    <div className="__footer_text">{CREATE_CLASS_FOOTER_TEXT}</div>
                    <div className="button_container">
                        <PrimaryButton
                            onClick={onSubmit}
                            fullWidth={false}
                            disabled={getDesabled()}
                            isLoading={false}>
                            Next
                        </PrimaryButton>
                    </div>
                </div>
            </form>
        </GridContainer>
    );
};

export default ClassName;
