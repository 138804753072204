import React, { createContext, useContext, useState,ReactNode } from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';

interface SnackbarContextType {
  showSnackbar: (message: string, severity?: 'error' | 'warning' | 'info' | 'success') => void;
}

interface SnackbarProviderProps {
    children: ReactNode;
  }

const SnackbarContext = createContext<SnackbarContextType | undefined>(undefined);

export const useSnackbar = () => {
  const context = useContext(SnackbarContext);
  if (!context) {
    throw new Error('useSnackbar must be used within a SnackbarProvider');
  }
  return context;
};

export const SnackbarProvider: React.FC<SnackbarProviderProps> = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState<AlertProps['severity']>('info');

  const handleClose = () => {
    setOpen(false);
  };

  const showSnackbar = (msg: string, severity: AlertProps['severity'] = 'info') => {
    setMessage(msg);
    setSeverity(severity);
    setOpen(true);
  };

  return (
    <SnackbarContext.Provider value={{ showSnackbar }}>
      {children}
      <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right'  }} open={open} autoHideDuration={1500} onClose={handleClose}>
        <MuiAlert sx={{ bgcolor: '#000' }} elevation={6} variant="filled" onClose={handleClose} severity={severity}>
          {message}
        </MuiAlert>
      </Snackbar>
    </SnackbarContext.Provider>
  );
};
