import GridContainer from 'components/molecules/GridContainer';
import React, { useEffect, useState } from 'react';
import './index.scss';
import { useNavigate, useParams } from 'react-router-dom';
import { colors } from 'assets/styles/colors';
import { FiInfo } from "react-icons/fi";
import { CircularProgress, FormControl, InputAdornment, InputLabel, MenuItem, Select, SelectChangeEvent, TextField, Tooltip, Typography } from '@mui/material';
import { TEMPLATE_INFO_TEXT } from 'utils/constants';
import { restAPIs } from 'utils/restAPIs';
import { useCustomSWR } from 'utils/customHooks';
import _ from 'lodash';
import Images from 'assets/image'
import PrimaryButton from 'components/atoms/CustomButtons';
import { FormControlLabel, Radio } from '@mui/material';
import { fetchData } from 'utils/fetch';
import { useDispatch } from 'react-redux';
import { clearClassroomData, setActiveClassRoom } from '../../redux/slices/setClassroom/classRoomSlice';
import { updateUserClassRoom } from '../../redux/slices/setUser/userSlice';


const Template: React.FC = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch()
    const { name, id } = useParams()
    const [isSearching, setIsSearching] = useState(false);
    const [searchText, setSearchText] = useState<string>('');
    const [term, setTerm] = useState<string>('');
    const [board, setBoard] = useState<string>('');
    const [templateItem, setTemplateItem] = useState([]);
    const [availableFilters, setAvailableFilters] = useState<any>([]);
    const [availableFilterData, setAvailableFilterData] = useState<any>([]);
    const [isSelectedUniversity, setIsSelectedUniversity] = useState(false);
    const [selectedFilters, setSelectedFilters] = useState<any>({});
    const [boardTemplates, setBoardTemplates] = useState<any>([]);
    const [filteredData, setFilteredData] = useState([]);
    const [selectedRadioId, setSelectedRadioId] = useState<string|null>(null);
    const [selectedTages, setSelectedTages] = useState('');
    const [isButtonLoading, setIsButtonLoading] = useState<boolean>(false);

    let params = {
        term: term?.trim(),
        count: 100,
        offset: 0,
    };

    let boardParams = {
        term: board,
        count: 100,
        offset: 0,
    };

    const { data, isLoading, isError } = useCustomSWR(restAPIs.getTemplateFeed(params));
    const { data: boardTemplatesData, isLoading: boardIsLoading, isError: boardIsError } = useCustomSWR(restAPIs.getBoardTemplate(boardParams));

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setSearchText(value);
    };

    const onSearch = (searchText: string) => {
        setTerm(searchText);
        setSelectedFilters({})
        setIsSearching(false)
    };

    useEffect(() => {
        if (data && term?.trim()?.length > 0) {
            setTemplateItem((prev: any) => data?.data?.filters);
        }
    }, [data]);

    useEffect(() => {
        if (boardTemplatesData && board?.length > 0) {
            setBoardTemplates((prev: any) => boardTemplatesData?.data?.templates);
        }
    }, [boardTemplatesData]);

    useEffect(() => {
        setIsSelectedUniversity(false)
        setIsSearching(true)
        setSelectedFilters({})
        setFilteredData([])
        setSelectedTages('');
        setSelectedRadioId(null)
        let searchWaiting = setTimeout(() => onSearch(searchText), 1000);
        return () => {
            clearTimeout(searchWaiting);
        };
    }, [searchText]);

    const onSubmit = (isSkip?:boolean) => {
        onJoin(isSkip)
    }

    const onJoin = async(isSkip?:boolean) => {
        let params =isSkip? {
            name: name,
            classRoomId: id,
          }:{
          name: name,
          classRoomId: id,
          templateId: selectedRadioId,
          descriptionTags: selectedTages,
        };
        setIsButtonLoading(true);
        const response = await fetchData(restAPIs.getClassRoomAdd(params),navigate)
        if(response?.status===200){
            dispatch(clearClassroomData())
            localStorage.removeItem('classroomData')
            dispatch(updateUserClassRoom(response?.data))
            if(isSkip){
                dispatch(setActiveClassRoom(response?.data))
                navigate(`/set-privacy`)
            }
            else{
            navigate(`/classroomfetch/${response?.data?._id}/clone`)
            }
        }
        setIsButtonLoading(false);
    }
      
    const onSelectBoard = (item: any) => {
        let Filter = [];
        let temp_filtres = [];
        for (let key in item) {
            if (Array.isArray(item[key]) && item[key].length > 0) {
                temp_filtres.push(item[key]);
                Filter.push(key);
            }
        }
        setIsSelectedUniversity(true);
        setAvailableFilters(Filter);
        setAvailableFilterData(temp_filtres);
        setBoard(item.board);
    }

    const renderUniversity = () => {
        return term?.trim()?.length > 0 && templateItem?.map((item: any) => {
            return (
                <div onClick={() => { onSelectBoard(item) }} className='__board_university'>
                    {item?.board}
                </div>
            )
        })
    } 
    const handleRadioChange = (item:any) => {
        if (selectedRadioId === item?._id) {
            setSelectedRadioId(null);
            setSelectedTages('');
        } else {
            setSelectedRadioId(item?._id);
            setSelectedTages(item.descriptionTags);
        }
    };

    const renderBoardTemplate = () => {
        const temp = Object.keys(selectedFilters).length === 0 ? boardTemplates : filteredData;

        return boardIsLoading ? <div className='progress' style={{ marginTop: '20px' }}>
            <CircularProgress size={20} sx={{ color: colors?.primary }} />
        </div> : temp?.map((item: any,index:number) => {
            return (
                <div onClick={() => handleRadioChange(item)} className="__board_template">
                    <img src={item.logo?.length > 0 ? item.logo : Images.University} className='__board_logo' />
                    <div className='__board_container'>
                        <div onClick={() => { onSelectBoard(item) }} className='__board'>
                            {item?.name}
                        </div>
                        <h3 className='description_tags'>{item.descriptionTags}</h3>
                    </div>
                    <FormControlLabel
                        control={
                            <Radio
                            checked={selectedRadioId === item._id}
                        />
                        }
                        label=""
                        className="__board_checkbox"
                    />
                </div>
            )
        })
    }

    const handleFilterChange = (event: SelectChangeEvent<string[]>) => {
        const { name, value } = event.target
        onSelectFilter(value, name)
    };

    const onSelectFilter = (item: any, tempType: string) => {

        let filter_array = [];
        filter_array = boardTemplates?.filter(function (value: any) {
            if (value.filters.semester.toLowerCase().includes(item.toLowerCase())) {
                return value;
            }
            if (value.filters.department.toLowerCase().includes(item.toLowerCase())) {
                return item;
            }
            if (value.filters.class.toLowerCase().includes(item.toLowerCase())) {
                return value;
            }
            if (value.filters.grade.toLowerCase().includes(item.toLowerCase())) {
                return value;
            }
        });
        let temp_item = selectedFilters;
        if (item == 'all') {
            delete temp_item[tempType];
        } else {
            temp_item[tempType] = item;
        }
        setSelectedFilters((prev: any) => temp_item);
        setFilteredData(filter_array);
        setTimeout(() => {
            filter();
        }, 10);
    };

    const filter = () => {
        const temp_filterdata = boardTemplates?.filter((item: any) => {
            const filterMatch = Object.keys(selectedFilters).every(filter => {
                return selectedFilters[filter] === item.filters[filter];
            });
            return filterMatch;
        });
        setFilteredData(temp_filterdata);
    };

    const renderAvailableFilters = () => {
        return availableFilters?.map((item: any, index: number) => {
            return (
                <FormControl sx={{ m: 1, minWidth: 180, marginLeft: 0 }}>
                    <InputLabel id="render-filters-select-label">{_.capitalize(item)}</InputLabel>
                    <Select
                        labelId="render-filters-select-label"
                        label={item}
                        value={selectedFilters[item]}
                        name={item}
                        onChange={handleFilterChange}
                        style={{ width: '100%', flex: '1', height: '40px', textAlign: 'left' }}
                    >
                        <MenuItem key={'all'} value="all">
                            All
                        </MenuItem>
                        {availableFilterData[index].map((filteritem: any) => (
                            <MenuItem key={filteritem} value={filteritem}>
                                {filteritem}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            )
        })
    }

    const getButtonEnabled = () => {
        if (isSelectedUniversity&&selectedRadioId) {
            return false
        }
        return true
    }

    return (
        <GridContainer
            pageTitle={name}
            backButton
            overflowY={'hidden'}
            leftGridPartition={3 / 4}
        >
            <div className="__template_content_container">
                <div className='template_container'>
                    <div className='__template'>
                        <div className="skip__header">
                            <h1 className='title'>Select your</h1>
                            <div onClick={()=>onSubmit(true)} className="skip">Skip</div>
                        </div>
                        <h1 className='__sub_title'>Board/University
                            <Tooltip title={TEMPLATE_INFO_TEXT}>
                                <div>
                                    <FiInfo size={18} cursor={'pointer'} color={colors.tertiary} />
                                </div>
                            </Tooltip>
                        </h1>
                        <div className="input_container">
                            <TextField
                                label="Search University/Board"
                                fullWidth
                                variant="outlined"
                                onChange={handleChange}
                                margin="normal"
                                InputLabelProps={{
                                    style: { fontSize: '13px', fontWeight: 400, color: "#B4B4B4" },
                                }}
                                className="input-field"
                                value={searchText}
                                style={{ marginBottom: '10px' }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            {(isLoading || isSearching) && term?.trim()?.length > 0 &&
                                                <CircularProgress size={15} sx={{ color: colors?.primary }} />}
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </div>
                        <div className='__filters'>
                            {isSelectedUniversity && renderAvailableFilters()}
                        </div>
                        {!isSelectedUniversity && renderUniversity()}
                        {isSelectedUniversity && renderBoardTemplate()}
                    </div>
                    <div style={{ marginBottom: '10vh' }}></div>
                </div>
                <div className='template__footer_container'>
                    <PrimaryButton
                        onClick={onSubmit}
                        fullWidth={false}
                        disabled={getButtonEnabled()}
                        isLoading={isButtonLoading}>
                        Next
                    </PrimaryButton>
                </div>
            </div>
        </GridContainer>
    );
};

export default Template;
