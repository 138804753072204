import React from 'react';
import './index.scss';
import GridContainer from '../../components/molecules/GridContainer';
import ClassmatesListWrapper from '../../components/atoms/ClassmatesListWrapper';

const Classmates: React.FC = () => {

    return (
        <GridContainer
        pageTitle={'Classmates'}
        backButton
        leftGridPartition={3/4}
        >
            <div >
                <div style={{padding: '0px 20px'}}>
                    <ClassmatesListWrapper />
                </div>

            </div>

        </GridContainer>
    );
};

export default Classmates;
