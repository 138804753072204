import React from 'react';
import { useSelector } from 'react-redux';
import './index.scss'

interface Props {
  setSelected: React.Dispatch<React.SetStateAction<any>>;
  selected?: any;
  onFilterSet: () => void
}

const FilterList: React.FC<Props> = ({ selected, setSelected, onFilterSet }) => {

  const { classroomData } = useSelector((state: any) => state.classRoom) || {};
  const subjects = classroomData.subjectList

  const onClick = (item: any) => {
    setSelected(item)
    onFilterSet()
  }

  return (
    <div className='filter-list'>
      <div onClick={() => onClick(null)} className={`list ${selected === null ? 'selected' : ''}`}>All</div>
      {subjects?.map((item: any) => (
        <div onClick={() => onClick(item)} className={`list ${item?._id === selected?._id ? 'selected' : ''}`}>{item.name}</div>
      ))}
    </div>
  );
};

export default FilterList;
