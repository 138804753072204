import React, { useEffect, useRef, useState } from 'react';
import { Avatar, CircularProgress } from '@mui/material';
import './index.scss';
import { ActivityList, SingleActivity } from './styles';
import { ProfileCard } from 'assets/styles/commonStyle';
import { useSelector } from 'react-redux';
import { fetchData } from 'utils/fetch';
import { restAPIs } from 'utils/restAPIs';
import { useNavigate } from 'react-router-dom';
import { getTime } from 'utils/commonUtils';
import { NOTIFICATION_MODULES } from 'utils/constants';
import { useCustomSWR } from 'utils/customHooks';

const RecentActivities: React.FC = () => {
    const { user } = useSelector((state: any) => state.user) || {};
    const { activeClassroom } = useSelector((state: any) => state.classRoom) || {};
    const [activities, setActivities] = useState<any[]>([]);
    const navigate = useNavigate();
    const [page, setPage] = useState(0);
    const [isLastPage, setIsLastPage] = useState(false);
    const scrollRef = useRef<HTMLDivElement>(null);

    let params = {
        classRoomId: activeClassroom?._id,
        offset: page
    };
    const { data,isLoading, isError, mutate } = useCustomSWR(restAPIs.getNotificationList(params));

    useEffect(() => {
        if (data) {
            setActivities((prev:any) =>page===0?data?.data?.notifications: [...prev,...data?.data?.notifications]);
        }
      }, [data]);


    useEffect(() => {
        const handleScroll = () => {
            
            if (scrollRef.current) {
                const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;
                if (scrollTop + clientHeight >= scrollHeight - 5 && !isLoading && !isLastPage) {
                    loadMore();
                }
            }
        };

        const currentScrollRef = scrollRef.current;
        if (currentScrollRef) {
            currentScrollRef.addEventListener('scroll', handleScroll);
        }

        return () => {
            if (currentScrollRef) {
                currentScrollRef.removeEventListener('scroll', handleScroll);
            }
        };
    }, [isLoading, isLastPage]);

    const loadMore = () => {
        if (!data?.data?.isLastPage) {
            setPage(prevPage => prevPage + 1);
        }
    }

    // const loadMore = () => {
    //     if (!isLastPage) {
    //         setPage((prevPage) => prevPage + 1);
    //         // fetchActivities(page + 1);
    //     }
    // };

    // const fetchActivities = async (pagetoFetch:any) => {
    //     setLoading(true);
    //     let params = {
    //         classRoomId: activeClassroom?._id,
    //         offset: pagetoFetch
    //     };
    //     const res = await fetchData(restAPIs.getNotificationList(params));
    //     console.log('params',res)
    //     setLoading(false);
    //     if (res.status === 200 && res.data) {
    //         setActivities((prevActivities) => prevActivities.concat(res.data.notifications));
    //         setIsLastPage(res.data.isLastPage);
            
    //     }
    // };

    const onClick = (type:string, id:string, item:any) => {
        let refId = item && item.refId && item.refId;
        if (type === NOTIFICATION_MODULES.document) {

            navigate(`/documents/ref/${refId}`)
        //   navigate('Documents', {
        //     subjectName: '',
        //     type: 'old',
        //     from: 'post',
        //     docId: refId,
        //   });
        }
        // if (type === NOTIFICATION_MODULES.post) {
        //   navigate('PostDetails', {postId: refId});
        // }
        if (type === NOTIFICATION_MODULES.note) {
            navigate(`/notes/ref/${refId}`)
        //   navigate('Notes', {
        //     subjectId: '',
        //     type: 'old',
        //     from: 'post',
        //     noteId: refId,
        //   });
        }
        if (type === NOTIFICATION_MODULES.event) {
            navigate('/events')
        //   navigate('EventDetails', {eventId: refId});
        }
        if (type === NOTIFICATION_MODULES.curriculum) {
          if (id === activeClassroom?._id) {
            // navigate('Curriculum', {subId: item.extra?.subjectId});
            navigate('/curriculum')
          } 
        //   else {
        //     otherClassroom(type, id);
        //   }
        }
        if (type === NOTIFICATION_MODULES.timetable) {
          if (id === activeClassroom?._id) {
            navigate('/timetable');
          } 
        //   else {
        //     otherClassroom(type, id);
        //   }
        }
        if (type === NOTIFICATION_MODULES.subject) {
          if (id === activeClassroom?._id) {
            // navigate('AddSubject');
            navigate('/subjects')
          } 
        //   else {
        //     otherClassroom(type, id);
        //   }
        }
        // if (type === NOTIFICATION_MODULES.userApproval) {
        //   navigate('ClassroomDetail', {
        //     id: item.classRoomId,
        //     membershipStatus: 'ACTIVE',
        //   });
        // }
        // if (type === NOTIFICATION_MODULES.userAdmin) {
        //   if (id === activeClassroom?._id) {
        //     navigate('Classroom');
        //   } else {
        //     navigate('ClassroomDetail', {
        //       id: item.classRoomId,
        //       membershipStatus: 'ACTIVE',
        //     });
        //   }
        // }
        // if (type === NOTIFICATION_MODULES.user) {
        //   navigate('UserProfile', {id: refId, from: 'classmates'});
        // }
      };

    return (
        <div className='home text-left __recent' ref={scrollRef}>
            <h4 className='__common_head'>Notifications</h4>
            <ActivityList>
                {activities?.map((item: any, index: any) => {
                    return (
                        <div style={{cursor:'pointer'}} onClick={() => onClick(item.module, item.classRoomId, item)}>
                        <SingleActivity key={index}>
                            <ProfileCard style={{ alignItems: 'center' }}>
                                <Avatar sx={{ bgcolor: '#222', width: 34, height: 34, fontSize: '14px' }} aria-label="recipe" src={item?.triggeredBy?.profilePic}>
                                    {item?.triggeredBy?.firstName?.slice(0, 1)}{item?.triggeredBy?.lastName?.slice(0, 1)}
                                </Avatar>
                                <div className='___label'>
                                    {item?.module === 'POST' ? <h1 style={{ fontSize: '13px' }}>{item?.title}</h1> : <h1 style={{ fontSize: '13px', fontWeight: '400' }}>{item?.triggeredBy?.firstName} {item?.triggeredBy?.lastName}</h1>}
                                    {item?.module === 'POST' && <p>{getTime(item?.createdAt)}</p>}
                                </div>
                            </ProfileCard>
                            <h3 className='__title'>{item?.module == 'POST' && item?.title}</h3>
                            <span className='__title'>{item?.module !== 'POST' && item?.description}</span>
                            <p>{item?.module !== 'POST' && <p>{getTime(item?.createdAt)}</p>}</p>
                        </SingleActivity>
                         </div>
                    );
                })}
                {isLoading && <div style={{textAlign:'center'}}>
                    <CircularProgress size={'20px'} color="primary" />
                    </div>}
            </ActivityList>
        </div>
    );
};

export default RecentActivities;
