import {createSlice} from '@reduxjs/toolkit';
import { loadUserFromLocalStorage } from 'utils/commonUtils';


const user = loadUserFromLocalStorage();

const userSlice = createSlice({
  name: 'userDetails',
  initialState: {
    user: null,
    loginForm:{},
  },
  reducers: {
    setUserDetails: (state, action) => {
      state.user = action?.payload;
      localStorage.setItem('sessiontoken', action?.payload?.token);
      localStorage.setItem('user',JSON.stringify(action?.payload));
    },
    clearUserDetails: (state) => {
      state.user = null;
      localStorage.clear()
    },
    setLoginForm: (state, action) => {
      state.loginForm = action?.payload;
    },
    updateUserDetails: (state, action) => {
      let temp_user={...user,...action?.payload}
      state.user = temp_user;
      localStorage.setItem('user',JSON.stringify(temp_user));
    },
    updateUserClassRoom: (state, action) => {
      let temp_user = state.user;
      // @ts-ignore
      temp_user?.classRooms?.push(action?.payload);
      state.user = temp_user;
      localStorage.setItem('user',JSON.stringify(temp_user));
    },
  },
});

export const {
  setUserDetails,
  clearUserDetails,
  setLoginForm,
  updateUserDetails,
  updateUserClassRoom,
} = userSlice.actions;
export default userSlice.reducer;
