import './index.scss'
import class_cover from '../../../assets/images/class_cover.png'
import class_logo from '../../../assets/images/class_logo.png'
import TabView from '../../molecules/TabView'
import ModalView from '../ModalView';
import { useDispatch, useSelector } from 'react-redux';
import { getStatus } from 'utils/commonUtils';
import { setSelectedClassRoom } from '../../../redux/slices/setClassroom/classRoomSlice';
import { useJoinRoom, useSwichClass } from 'utils/customHooks';
import { useSnackbar } from '../SnackbarProvider';

interface ChildProps {
    item: any;
    onClose?: () => void
}

const ClassRoomDetailModal: React.FC<ChildProps> = ({ item, onClose }) => {

    const { user } = useSelector((state: any) => state.user) || {};
    const classRooms = user?.classRooms
    const dispatch = useDispatch();
    const swichClass = useSwichClass()
    const joinRoom = useJoinRoom();
    const { showSnackbar } = useSnackbar();

    const onSubmitClick = () => {
        if (getStatus(item, classRooms) !== 'Pending') {
            dispatch(setSelectedClassRoom(item))
            if (getStatus(item, classRooms) === 'Joined') {
                swichClass(item?._id, true)
            }
            else {
                joinRoom(item?._id, item?.public)
            }
        }
        else{
            showSnackbar('Waiting for Classroom Admin Approval', 'info')
        }
    }

    return (
        <ModalView
            open={item !== null}
            modalStyle={{}}
            handleClose={onClose}>
                <div className='classroom-detail-modal'>
                    <div style={{
                        backgroundImage: `linear-gradient(to bottom,rgba(0,0,0,0),rgba(0,0,0,0.0) ,rgba(0,0,0,0.2),rgba(0,0,0,.4),rgba(0,0,0,.7)),
                        url(${item?.cover || class_cover})`,
                    }}
                        className="class-room-detail-cover">
                        <img src={item?.logos?.small?.length > 0 ? item?.logos?.small : class_logo} className='class_logo' />
                        <div className='detail-cover-wrapper'>
                            <span className='detail-cover-text'>{item?.name}</span>
                            <span className='detail-cover-text id'>#{item?.classRoomId}</span>
                        </div>
                    </div>
                    <div>
                        <TabView onClick={onSubmitClick} item={item} />
                    </div>
                </div>
        </ModalView>
    );
};

export default ClassRoomDetailModal;

