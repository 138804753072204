import React, { FC } from 'react';
import './index.scss';

interface props{
    isVisible:boolean;
    setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
    scrollRef: {
        current?: HTMLElement | null;
      }
}

const ScrollToTopButton: FC<props>= ({isVisible,setIsVisible,scrollRef}) => {

    const scrollToTop = () => {
        scrollRef?.current?.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    return (
        <div className="scroll-to-top">
            {isVisible && (
                <button onClick={scrollToTop} className="scroll-to-top-button">
                    ↑ move to top
                </button>
            )}
        </div>
    );
};

export default ScrollToTopButton;
