import dayjs from 'dayjs';
import _ from 'lodash';
import {parsePhoneNumberFromString,isValidPhoneNumber} from 'libphonenumber-js';

export function formatName(firstName: string, lastName: string) {
  let Temp_lastName = lastName ? lastName : '';
  let Temp_firstName = firstName ? firstName : '';
  let fullName = Temp_firstName + ' ' + Temp_lastName;
  fullName = fullName?.slice(0, 25) + (fullName?.length > 25 ? '...' : '');
  return fullName;
}

export const loadUserFromLocalStorage = () => {
  try {
    const userString = localStorage.getItem('user');
    if (userString) {
      return JSON.parse(userString);
    }
  } catch (error) {
    console.error('Error loading user data from localStorage:', error);
    return null;
  }
  return null; // Return null if no user data found
};

export const loadClassroomDataLocalStorage = () => {
  try {
    const classroomDataString = localStorage.getItem('classroomData');
    if (classroomDataString) {
      return JSON.parse(classroomDataString);
    }
  } catch (error) {
    console.error('Error loading user data from localStorage:', error);
    return null;
  }
  return null; // Return null if no user data found
};

export const loadActiveClassroomLocalStorage = () => {
  try {
    const activeClassroomString = localStorage.getItem('activeClassroom');
    if (activeClassroomString) {
      return JSON.parse(activeClassroomString);
    }
  } catch (error) {
    console.error('Error loading user data from localStorage:', error);
    return null;
  }
  return null; // Return null if no user data found
};

export const saveUserToLocalStorage = (userData: any) => {
  try {
    localStorage.setItem('user', JSON.stringify(userData));
  } catch (error) {
    console.error('Error saving user data to localStorage:', error);
  }
};


type Params = Record<string, string | number>;
export const buildGetEndpoint = (endpoint: string, params: Params): string => {
  // Create a URLSearchParams object
  const urlSearchParams = new URLSearchParams();

  // Loop through each parameter and add it to URLSearchParams
  for (const [key, value] of Object.entries(params)) {
    urlSearchParams.append(key, String(value)); // Ensure value is a string
  }

  // Append the query string to the endpoint URL
  return `${endpoint}?${urlSearchParams.toString()}`;
};

export const isActicve = (id: string, classRooms: any) => {
  let isActicve = false;
  let array = classRooms;
  array.map((item: any) => {
    if (item._id === id) {
      if (item.membershipStatus == 'ACTIVE') {
        isActicve = true;
      }
    }
  });
  return isActicve;
};

export const isAlreadyJoined = (id: string, classRooms: any) => {
  let selected = false;
  let array = classRooms;
  array.map((item: any) => {
    if (item._id === id) selected = true;
  });
  return selected;
};

export const getStatus = (item: any, classRooms: any) => {
  if (isAlreadyJoined(item?._id, classRooms)) {
    if (isActicve(item?._id, classRooms))
      return 'Joined'
    else return 'Pending'
  }
  else {
    if (item?.public)
      return 'Join'
    else return 'Request'
  }
}

export const formatAMPM = (dateString: any) => {
  let timeStamp = Date.parse(dateString);
  let date = new Date(timeStamp);
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  const minutesStr = minutes < 10 ? '0' + minutes : minutes;
  var strTime = hours + ':' + minutesStr + ' ' + ampm;
  return strTime;
}

export const getTime = (time: any) => {
  let lastActivity = null;
  var relativeTime = require('dayjs/plugin/relativeTime');
  dayjs.extend(relativeTime);
  // @ts-ignore
  lastActivity = dayjs(time).fromNow();
  return lastActivity;
}

export function getFileType(note: any) {
  switch (note.type) {
    case 'image/jpeg':
    case 'image/jpg':
    case 'image/png':
    case 'image/gif':
    case 'image/webp':
      return 'Image';
    case 'application/pdf':
      return 'PDF';
    case 'video/mp4':
      return 'Video';
    case 'application/msword':
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.template':
      return 'Document';
  }
  return 'File';
}

export const filterDataBySubjectName = (data: any, subjectName: any) => {
  return _.filter(data, item => item.subjectId?.name === subjectName);
};

export const filterIndexBySubjectName = (data: any[], subjectName: any, setIndex: (index: number) => void) => {
  const index = _.findIndex(data, item => item?.name === subjectName);
  if (index !== -1) {
    setIndex(index);
  }
};

export const getSize=(size:any)=> {
  if (size >= 1073741824) {
    size = (size / 1073741824).toFixed(2) + ' GB';
  } else if (size >= 1048576) {
    size = (size / 1048576).toFixed(2) + ' MB';
  } else if (size >= 1024) {
    size = (size / 1024).toFixed(2) + ' KB';
  } else if (size > 1) {
    size = size + ' bytes';
  } else if (size == 1) {
    size = size + ' byte';
  } else {
    size = '0 bytes';
  }
  return size;
}

export const getTypeString = (type:string) => {
  switch (type) {
    case 'EVENT':
      return 'added a event.';
    case 'NOTE':
      return 'added a note.';
    case 'DOCUMENT':
      return 'added a document.';
    default:
      return false;
  }
};

export const checkType = (type: string) => {
  switch (type) {
      case 'EVENT':
          return true;
      case 'NOTE':
          return true;
      case 'DOCUMENT':
          return true;
      default:
          return false;
  }
};


export const isNumberValid = (phoneNumber:string, country:any) => {
  try {
    const phoneNumberObj = parsePhoneNumberFromString(phoneNumber, country);
    if (phoneNumberObj && phoneNumberObj.isValid()) {
      return true; // Valid phone number format
    }
  } catch (error) {
    // Handle any errors
  }
  return false; // Invalid phone number format
};