import React, { useEffect, useState } from 'react';
import './index.scss'
import { PublicSharp } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { formatName, getTime } from 'utils/commonUtils';

interface Props {
    item: any;
}

const ClassRoomDetails: React.FC<Props> = ({ item }) => {

    const classMetaData = useSelector(
        (state: any) => state.classRoom?.SelectedClassMetadata
    );
    const [board, setBoard] = useState<any>([]);

    useEffect(() => {
        getBoardTags();
    }, []);

    async function getBoardTags() {
        let filterTags = item?.templateId?.filters
            ? item?.templateId?.filters
            : {};
        delete filterTags.filterId;
        const myArr = await Object.values(filterTags);
        const finalmyArr = await myArr.filter(item => item);
        setBoard(finalmyArr);
        return 'finalmyArr'
    }

    const data = [
        { Name: item?.name },
        { ID: item?.classRoomId },
        { About: item?.about?.length > 0 ? item?.about : 'No description' },
        { Privacy: item?.public ? 'Public  ' : 'Private  ' },
        { 'University/Board': 'cusat' },
        { 'Last Activity': classMetaData ? getTime(classMetaData?.lastActivity) : 'Loading...' },
        {
            ' Created by': classMetaData ? formatName(
                classMetaData?.createdBy?.firstName,
                classMetaData?.createdBy?.lastName,
            ) : 'Loading...'
        }
    ]

    return (
        <div className='classroom-details'>
            {data?.map((item, index) => {
                return (
                    <div className='classroom-details-wrapper'>
                        {Object.entries(item).map(([key, value]) => (
                            <div className='classroom-details-card'>
                                <span className='key-text'>{key}</span>
                                <span className='value-text'>{value}{item?.Privacy && <span><PublicSharp className='Drawer-icon' sx={{ fontSize: 18, marginLeft: '5px' }} /></span>}</span>
                            </div>
                        ))}
                        {index !== data?.length - 1 &&
                            <div className='line-separator' />}
                    </div>

                )
            })}
        </div>
    );
};

export default ClassRoomDetails;
