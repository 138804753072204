import React, { useEffect, useState } from 'react';
import './index.scss';
import GridContainer from '../../components/molecules/GridContainer';
import { CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { getTime } from 'utils/commonUtils';
import { fetchData } from 'utils/fetch';
import { restAPIs } from 'utils/restAPIs';
import UrlContentTextWrapper from '../../components/atoms/UrlContentTextWrapper';

const MyActivities: React.FC = () => {
    const [activities, setActivities] = useState<any>([])
    const navigate = useNavigate();
    const [page, setPage] = useState(0);
    const [isLastPage, setIsLastPage] = useState(false);
    const [isLoding, setLoading] = useState(false);

    useEffect(() => {
        setPage(0)
        fetchActivities();
    }, []);

    const loadMore = () => {
        if (!isLastPage) {
            fetchActivities()
        }
    }

    const fetchActivities = async () => {
        setLoading(true)
        let params = {
            offset: page
        };
        const res = await fetchData(restAPIs.getMyActivities(params),navigate)
        setLoading(false)
        if (res.status === 200 && res.data) {
            setActivities(activities.concat(res.data.activity))
            setIsLastPage(res.data.isLastPage);
            setPage(page + 1)
        }
    }

    return (
        <GridContainer
            loading={isLoding}
            loadMore={loadMore}
            page={page}
            pageTitle={'My Activities'}
            backButton
            leftGridPartition={3 / 4}
        >
            <div>
                <div className='__activities_lists'>
                    {activities?.map((item: any, index: any) => {
                        return (
                            <div className='__single_activity'>
                                <UrlContentTextWrapper content={item?.description ? item?.description : item?.title} />
                                <p className='__created_at'>{getTime(item?.createdAt)}</p>
                            </div>
                        )
                    }
                    )}
                    {isLoding && <CircularProgress size={'20px'} color="primary" />}
                </div>

            </div>

        </GridContainer>
    );
};

export default MyActivities;
