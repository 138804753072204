import React, { useState } from 'react';
import './index.scss';
import GridContainer from '../../components/molecules/GridContainer';
import { Typography } from '@mui/material';
import { fetchData } from 'utils/fetch';
import { S3UPLOAD, restAPIs } from 'utils/restAPIs';
import { useSnackbar } from '../../components/atoms/SnackbarProvider';
import { getFileType } from 'utils/commonUtils';
import { MdAdd, MdDelete } from 'react-icons/md';
import PrimaryButton from '../../components/atoms/CustomButtons';
import { useSelector } from 'react-redux';
import Axios from 'axios';
import { useNavigate } from 'react-router-dom';


interface SubjectChange {
    target: {
        value: string;
    };
}
interface FormErrorState {
    description: string;
}

const initialerrors = {
    description: ''
}
interface FileItem {
    file: File;
    type: string;
}

const iconStyle = {
    width: '25px',
    marginRight: '10px'
}

const ReportAProblem: React.FC = () => {
    const { user } = useSelector((state: any) => state.user) || {};
    const navigate = useNavigate();
    const [isLoding, setLoading] = useState(false);
    const { showSnackbar } = useSnackbar();
    const [errors, setErrors] = useState<FormErrorState>(initialerrors);
    const [description, setDescription] = useState('');
    const [files, setFiles] = useState<any>([]);
    const [uploadProgress, setUploadProgress] = useState<any>(false);

    const onUpload = async () => {
        if (files && files.length > 0) {
            setLoading(true);
            let uploadedData = files;
            let uploadFailed = false; 
            for (var i = 0; i < files.length; i++) {
                let id = user?._id;
                let formData = new FormData();
                formData.append('file', files[i].file);
                const config = {
                    headers: {
                        'Authorization': user?.token,
                    },
                    onUploadProgress: (progressEvent: any) => {
                        if (progressEvent.loaded > 100) {
                            setUploadProgress(
                                (i / files.length +
                                    progressEvent.loaded / progressEvent.total / files.length) *
                                99,
                            );
                        }
                    },
                };
                try {
                    const response = await Axios.post(
                        S3UPLOAD + `user/${id}/report/${files[i].file.name}`,
                        formData,
                        config,
                    );

                    if (response.status === 200) {

                        uploadedData[i].url = response.data.data.imageURL;
                        if (i === files.length - 1) {
                            handleSubmit(uploadedData, 'withFile');
                        }
                    } else {
                        uploadFailed = true;
                        break;
                    }
                } catch (error) {
                    uploadFailed = true;
                    break; 
                }
            }
            if (uploadFailed) {
                setLoading(false);

                showSnackbar('Something went wrong. Please try again!!', 'error');
            }
        } else {
            setLoading(true);
            handleSubmit(null, 'withoutFile');
        }
    }


    const handleSubmit = async (uploadedData: any, type: any) => {
        let params = {
            user: user?._id,
            title: '',
            description: description,
            files: uploadedData && uploadedData.length > 0 ? uploadedData : [],
            status: 'PENDING',
        };
        const res = await fetchData(restAPIs.reportProblem(params),navigate)
        setLoading(false)
        if (res.status === 200) {
            setFiles([])
            setDescription('')
            showSnackbar(res?.message, 'success')
        }
        else {
            showSnackbar('something went wrong !', 'error')
        }
    }

    const handleDescriptionChange = (event: SubjectChange) => {
        setDescription(event.target.value)
    };


    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            const newFiles = Array.from(event.target.files);
            const fileItems: any = newFiles.map(file => {
                const fileType = getFileType(file);
                return { file, type: fileType };
            });
            setFiles((prevFiles: any) => [...prevFiles, ...fileItems]);
        }
    };

    const handleDeleteFile = (index: number) => {
        const updatedFiles = [...files];
        updatedFiles.splice(index, 1);
        setFiles(updatedFiles);
    };



    return (
        <GridContainer pageTitle={'Report a Problem'} backButton leftGridPartition={3/4}>
            <div>
                <div className='__report_block'>
                    <div className="file-upload-container">
                        <div className="input-group">
                            <label>Description</label>
                            <textarea value={description} onChange={handleDescriptionChange} placeholder={'What went wrong ?'} required />
                            <Typography sx={{ color: 'red' }} variant='subtitle2'>{errors?.description}</Typography>
                        </div>
                        <div>
                            <p style={{ marginBottom: '10px' }}>Images {files.length > 0 && <span>({files.length} files)</span>}</p>
                            <div className="file-list-grid">
                                {files.map((fileItem: any, index: any) => {
                                    return (
                                        <div className="file_object" key={index}>
                                            <img src={URL.createObjectURL(fileItem.file)} alt="Preview" />
                                            <span><MdDelete style={{ cursor: 'pointer' }} color={'#fff'} size={15} onClick={() => handleDeleteFile(index)} /></span>
                                        </div>
                                    )
                                }
                                )}

                                <label className="file_object">
                                    <MdAdd size={32} color={'#888'} />
                                    <input type="file" multiple onChange={handleFileChange} accept='image/*' />
                                </label>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>

                            </div>
                        </div>
                        <div className='___footer-button'>
                            <PrimaryButton
                                disabled={description.length === 0}
                                onClick={onUpload}
                                fullWidth={false}
                                progress={uploadProgress}
                                isLoading={isLoding}
                            >
                                    Report
                             
                            </PrimaryButton>
                        </div>
                    </div>
                </div>

            </div>

        </GridContainer>
    );
};

export default ReportAProblem;
