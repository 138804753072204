import React, { FC } from 'react';
import { Grid } from '@mui/material';
import '../../assets/styles/globalStyles.scss'
import { useLocation } from 'react-router-dom';

const gridStyle = {
    borderBottom: '1px solid lightgray',
    borderLeft: '1px solid lightgray',
    // height: 'calc(100vh - 61px)',
}

interface ChildrenProps {
    children?: React.ReactNode;
    rightGridPartition?: any;
    overflowY?: 'auto' | 'hidden' | 'scroll' | 'visible';
}

const RightGrid: FC<ChildrenProps> = ({ children, rightGridPartition,overflowY = 'auto' }) => {

    const location = useLocation();

    return (
        <Grid className='right-grid' style={{...gridStyle, height : location.pathname?.includes('classroomfetch') ? 'calc(100vh)': 'calc(100vh - 61px)', overflowY: overflowY}}
            xs={12}
            sm={rightGridPartition ? rightGridPartition * 12 : 4}
            md={rightGridPartition ? rightGridPartition * 12 : 4}
            lg={rightGridPartition ? rightGridPartition * 12 : 4}
            item>
            {children}
        </Grid>
    );
};

export default RightGrid;
