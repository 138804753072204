import React, { useState, useRef, useEffect } from 'react';
import './index.scss';
import { useSnackbar } from '../SnackbarProvider';
import { fetchData } from "utils/fetch";
import { restAPIs } from "utils/restAPIs";
import CircularProgress from '@mui/material/CircularProgress';
import { colors } from 'assets/styles/colors';
import CropPicker from '../CropPicker';
import { useNavigate } from 'react-router-dom';

interface ImageCarouselProps {
    imageFile: any;
    setImageFile: React.Dispatch<React.SetStateAction<any>>;
    files?: any;
    editItem?: any;
}

const PostPhotoScroller: React.FC<ImageCarouselProps> = ({ imageFile, setImageFile, files, editItem }) => {
    const { showSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const [loadingIndex, setIsLoadingIndex] = useState<number | null>(null);
    const [selected, setSelected] = useState<null | number>(null);
    const containerRef = useRef<HTMLDivElement>(null);
    const [canScrollLeft, setCanScrollLeft] = useState(false);
    const [canScrollRight, setCanScrollRight] = useState(false);

    const handleDeleteFile = async (index: number) => {
        const updatedFiles = [...imageFile];
        const temp = updatedFiles.splice(index, 1);
        if (temp?.[0]?.url) {
            setIsLoadingIndex(index);
            const S3DeleteRes = await fetchData(restAPIs.getS3Delete(temp?.[0]?.url),navigate);
            setIsLoadingIndex(null);
            if (S3DeleteRes.status === 200 && S3DeleteRes.data) {
                setImageFile(updatedFiles);
            } else {
                showSnackbar('Something went wrong. Please try later', 'error');
            }
        } else {
            setImageFile(updatedFiles);
        }
    };

    const scrollLeft = () => {
        if (containerRef.current) {
            const distance = Math.min(300, containerRef.current.scrollLeft);
            containerRef.current.scrollBy({ left: -distance, behavior: 'smooth' });
        }
    };

    const scrollRight = () => {
        if (containerRef.current) {
            const { scrollLeft, scrollWidth, clientWidth } = containerRef.current;
            const distance = Math.min(300, scrollWidth - scrollLeft - clientWidth);
            containerRef.current.scrollBy({ left: distance, behavior: 'smooth' });
        }
    };

    const updateScrollButtons = () => {
        if (containerRef.current) {
            const { scrollLeft, scrollWidth, clientWidth } = containerRef.current;
            setCanScrollLeft(scrollLeft > 0);
            setCanScrollRight(scrollLeft + clientWidth < scrollWidth);
        }
    };

    useEffect(() => {
        updateScrollButtons();
        window.addEventListener('resize', updateScrollButtons);
        if (containerRef.current) {
            containerRef.current.addEventListener('scroll', updateScrollButtons);
        }

        return () => {
            window.removeEventListener('resize', updateScrollButtons);
            if (containerRef.current) {
                containerRef.current.removeEventListener('scroll', updateScrollButtons);
            }
        };
    }, [imageFile]);

    return (
        <div className='post_photo_scroller'>
            {canScrollLeft && (
                <button className="scroll-button left" onClick={scrollLeft}>
                    &#9664;
                </button>
            )}
            <div className="carousel-container" ref={containerRef}>
                {imageFile.map((image: any, index: number) => (
                    <div key={index} className="carousel-item">
                        <img
                            src={image?.url ?? URL.createObjectURL(image)}
                            className='img'
                            alt={image.name}
                        />
                        {!image?.url && (
                            <div onClick={() => setSelected(index)} className="crop-button">
                                <i className="fi fi-br-tool-crop icon-large"></i>
                            </div>
                        )}
                        <div onClick={() => handleDeleteFile(index)} className="crop-button right">
                            {loadingIndex === index ? <CircularProgress size={15} sx={{ color: colors?.white }} /> : <i className="fi fi-br-trash icon-large"></i>}
                        </div>
                    </div>
                ))}
            </div>
            {canScrollRight && (
                <button className="scroll-button right" onClick={scrollRight}>
                    &#9654;
                </button>
            )}
            <CropPicker editItem={editItem} selected={selected} setSelected={setSelected} imageFile={imageFile} files={files} setImageFile={setImageFile} />
        </div>
    );
};

export default PostPhotoScroller;

