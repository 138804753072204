import React, { useRef, useState } from 'react';
import './index.scss'
import ModalView from '../ModalView';
import PrimaryButton from '../CustomButtons';
import { IoIosClose } from "react-icons/io";
import { FEEDS } from "utils/restAPIs"
import { useSelector } from 'react-redux';
import { useSnackbar } from '../SnackbarProvider';
import { WriteOnBoard2 } from '../../../pages/Feed/styles';
import PhotoPicker from '../PhotoPicker';
import URLInput from '../URLInput';
import AttachFiles from '../AttachFiles';
import { Badge } from '@mui/material';
import axios from 'axios';
import PostPhotoScroller from '../PostPhotoScroller';

interface Props {
  handleClose?: () => void;
  open: any;
  modalStyle?: any;
  handleOpen?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  createType?: string;
  setFeed?: React.Dispatch<React.SetStateAction<any>>
  feed?: any
}

interface FileItem {
  file: File;
  type: string;
}

interface SubjectChange {
  target: {
    value: string;
  };
}

const CreatePostModal: React.FC<Props> = ({ open, handleClose, modalStyle, setFeed, feed }) => {


  const { showSnackbar } = useSnackbar();
  const [post, setPost] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { classroomData, activeClassroom } = useSelector((state: any) => state.classRoom) || {};
  const { user } = useSelector((state: any) => state.user) || {};
  const [imageFile, setImageFile] = useState<any>([]);
  const [percentage, setPercentage] = useState(0);
  const [inputVisible, setInputVisible] = useState<boolean>(false);
  const [files, setFiles] = useState<FileItem[]>([])
  const [urls, setUrls] = useState<string[]>([]);
  const fileAttachmentRef = useRef<HTMLInputElement>(null);

  const handlePostChange = (event: SubjectChange) => {
    setPost(event.target.value);
    setError('')
  }

  function fileToPlainObject(file: any) {
    return {
      name: file.name,
      lastModified: file.lastModified,
      lastModifiedDate: file.lastModifiedDate,
      size: file.size,
      type: file.type,
      webkitRelativePath: file.webkitRelativePath
    };
  }

  const onSubmit = () => {
    const filesArray = files.map(obj => obj.file);
    const uploadedData = [...imageFile, ...filesArray]

    const plainObjectArray = uploadedData?.map(file => fileToPlainObject(file));
    setIsLoading(true);
    const config = {
      headers: {
        Authorization: localStorage.getItem('sessiontoken'),
      },
      onUploadProgress: (progressEvent: any) => {
        setPercentage(
          Math.round((progressEvent.loaded * 100) / progressEvent.total),
        );
      },
    };
    let formData = new FormData();
    uploadedData.forEach(file => {
      formData.append('file', file, file.name);
    });
    formData.append('classRoomId', activeClassroom?._id);
    formData.append('userId', user?._id);
    // @ts-ignore
    formData.append('active', true);
    formData.append('content', post);
    formData.append(
      'documents',
      JSON.stringify(
        plainObjectArray?.length > 0 ? plainObjectArray : [],
      ),
    );
    formData.append(
      'externalInfo',
      JSON.stringify(urls?.length > 0 ? urls : []),
    );
    axios
      .post(FEEDS, formData, config)
      .then((response: any) => {
        if (response?.data?.status == 200 && response?.data?.data != null) {
          setIsLoading(false);
          let temp_res = response.data?.data
          temp_res.userId = {
            firstName: user?.firstName,
            lastName: user?.lastName,
            _id: user?._id,
            profilePic: user?.profilePic
          }
          let temp = [temp_res, ...feed]
          setFeed?.(temp)
          onClose()
        } else {
          setIsLoading(false);
          showSnackbar(response?.message ?? 'Something went wrong. Please try later', 'error')
        }
      })
      .catch(err => {
        showSnackbar(err?.response?.data?.message
          ? err?.response?.data?.message
          : 'Something went wrong. Please try later', 'error')
        setIsLoading(false);
      });
  };

  const onClose = () => {
    handleClose?.()
    setPost('')
    setError('')
    setFiles([])
    setImageFile([])
    setUrls([])
  }

  const RenderModal = () => (
    <div className="create-post-modal">
      <div className='__header'>
        <h4>Create Post</h4>
        <button onClick={onClose}><IoIosClose /></button>
      </div>
      <div className='__oute_body_wrap'>
        <div className='body'>
          <WriteOnBoard2 marginBottom={'0px'}>
            <textarea autoFocus value={post} onChange={handlePostChange} placeholder='Write On Board…' className='post-text-area'></textarea>
          </WriteOnBoard2>
          {error && <p className='___errror'>{error}</p>}

          {imageFile?.length > 0 &&
            <PostPhotoScroller setImageFile={setImageFile} files={files} imageFile={imageFile} />}
        </div>
        <URLInput urls={urls} setUrls={setUrls} inputVisible={inputVisible} />
        <AttachFiles fileInputRef={fileAttachmentRef} setFiles={setFiles} files={files} imageFile={imageFile} />
      </div>
      <div className='__buttons2'>
        <PhotoPicker imageFile={imageFile} files={files} setImageFile={setImageFile} />
        <i onClick={() => setInputVisible(!inputVisible)} className="fi fi-rr-link"></i>
        <Badge
          sx={{
            '& .MuiBadge-badge': {
              color: '#fff',
              fontWeight: 400
            },
          }}
          onClick={() => fileAttachmentRef.current?.click()}
          badgeContent={files?.length ? files?.length > 9 ? '9+' : files?.length : 0}
          color="primary">
          <i className="fi fi-rr-clip"></i>
        </Badge>
        <PrimaryButton
          disabled={post.length === 0 && imageFile.length == 0 && files.length === 0 && urls?.length === 0}
          fullWidth={false}
          progress={percentage}
          isLoading={isLoading} onClick={onSubmit}>POST</PrimaryButton>
      </div>
    </div>
  )
  return (
    <ModalView open={open} handleClose={onClose} modalStyle={modalStyle}>
      {RenderModal()}
    </ModalView>
  );
};

export default CreatePostModal;
