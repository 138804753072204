import React, { useEffect, useState } from 'react';
import './index.scss';
import { Avatar } from '@mui/material';

interface CommentInputProps {
  post: string;
  user: any;
  onSend: (comment: string) => void;
  editComments?: any;
  setEditComments?: React.Dispatch<React.SetStateAction<any>>
}

const PostComment: React.FC<CommentInputProps> = ({ post, user, onSend, editComments, setEditComments }) => {
  const [comment, setComment] = useState('');
  const [rows, setRows] = useState<number>(1);

  const handleSend = () => {
    if (comment.trim()) {
      onSend(comment);
      setComment('');
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      if (rows < 4) {
        setRows(rows + 1)
      }
    }
  };

  useEffect(() => {
    if (editComments) {
      setComment(editComments.comment)
    }
    else {
      setComment('')
    }

  }, [editComments]);

  return (
    <div className="comment-input">
      <Avatar
        src={user?.profilePic}
        sx={{ bgcolor: '#575757', width: 34, height: 34, fontSize: '14px' }}
        aria-label="recipe"
      >
        {user?.firstName?.slice(0, 1)}
        {user?.lastName?.slice(0, 1)}
      </Avatar>
      <textarea
        value={comment}
        onKeyDown={handleKeyDown}
        onChange={(e) => setComment(e.target.value)}
        placeholder="Write a comment..."
        className="comment-input-field"
        rows={rows} // Initial number of rows for the textarea
      />
      <div className='send-button-wrapper'>
        <button onClick={handleSend} className="send-button">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
          >
            <path fill="currentColor" d="M2 21l21-9L2 3v7l15 2-15 2z" />
          </svg>
        </button>
        {editComments &&
          <button onClick={() => setEditComments?.(null)} className="send-button">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="24"
              height="24"
            >
              <path fill="currentColor" d="M18.3 5.7a1 1 0 00-1.4 0L12 10.6 7.1 5.7a1 1 0 00-1.4 1.4l4.9 4.9-4.9 4.9a1 1 0 001.4 1.4l4.9-4.9 4.9 4.9a1 1 0 001.4-1.4L13.4 12l4.9-4.9a1 1 0 000-1.4z" />
            </svg>
          </button>}
      </div>
    </div>
  );
};

export default PostComment;
