import React, { useEffect, useState } from 'react';
import GridContainer from '../../components/molecules/GridContainer';
import './index.scss'
import { useDispatch, useSelector } from 'react-redux';
import { TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import PrimaryButton from '../../components/atoms/CustomButtons';
import { fetchData } from 'utils/fetch';
import { restAPIs } from 'utils/restAPIs';
import { updateUserDetails } from '../../redux/slices/setUser/userSlice'
import { useSnackbar } from '../../components/atoms/SnackbarProvider';
import { FIRST_NAME_LENGTH,LAST_NAME_LENGTH } from 'utils/constants';

interface FormState {
    firstName: string;
    lastName: string;
}
interface FormErrorState {
    firstName: string;
    lastName: string;
    message: string | null
}

const initialForm = {
    firstName: '',
    lastName: '',
}

const initialError = {
    firstName: '',
    lastName: '',
    message: ''
}

const ChangeName = () => {

    const { user } = useSelector((state: any) => state.user) || {};
    const { showSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const [formData, setFormData] = useState<FormState>(initialForm);
    const [errors, setErrors] = useState<FormErrorState>(initialError);
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
        if ((name === 'firstName' || name === 'lastName') && value.length > FIRST_NAME_LENGTH) {
            setErrors({ ...errors, [name]: `Maximum length is ${FIRST_NAME_LENGTH} characters` });
        } else {
            setErrors({ ...errors, [name]: '' });
        }
    };

    const validateForm = (): boolean => {
        let newErrors = { ...initialError };
        if (!formData.firstName) {
            newErrors.firstName = 'First Name is required';
        }
        if (formData.firstName?.length>FIRST_NAME_LENGTH) {
            newErrors.firstName = `Maximum length is ${FIRST_NAME_LENGTH} characters`;
          }
        if (!formData.lastName) {
            newErrors.lastName = 'Last Name is required';
        }
        if (formData.lastName?.length>LAST_NAME_LENGTH) {
          newErrors.lastName = `Maximum length is ${LAST_NAME_LENGTH} characters`;
        }

        setErrors(newErrors);
        return Object.values(newErrors).every((error) => error === '');
    };

    const handleSubmit = async() => {

        if (validateForm()) {
            const userForm = {
                firstName: formData?.firstName.trim(),
                lastName: formData?.lastName.trim()
              }
              setIsLoading(true)
              const res = await fetchData(restAPIs.putUserDetails(userForm),navigate);
              const response = res.data
              if (res.status === 200) {
                showSnackbar('Display name changed successfully', 'success')
                dispatch(updateUserDetails(response))
                setIsLoading(false)
                navigate(-1)
              }
              else {
                setIsLoading(false)
                setErrors({ message: response?.message, ...response?.fields })
              }

        }
    };

    useEffect(() => {
        setFormData({
            firstName: user?.firstName || '',
            lastName: user?.lastName || '',
        });
    }, [user]);

    return (
        <GridContainer  leftGridPartition={3/4} pageTitle={'Change Display Name'} backButton>
            <div style={{ marginTop: '0px' }}>
                <div style={{ padding: '0px 20px' }} className='__change_name'>
                    <TextField
                        label="First Name"
                        fullWidth
                        variant="outlined"
                        error={!!errors.firstName}
                        helperText={errors.firstName}
                        onChange={handleChange}
                        margin="normal"
                        name="firstName"
                        InputLabelProps={{
                            style: { fontSize: '13px', fontWeight: 400, color: "#B4B4B4" },
                        }}
                        className="input-field"
                        value={formData.firstName}
                        style={{ marginBottom: '10px' }}
                    />
                    <TextField
                        label="Last Name"
                        name="lastName"
                        fullWidth
                        error={!!errors.lastName}
                        helperText={errors.lastName}
                        variant="outlined"
                        onChange={handleChange}
                        margin="normal"
                        InputLabelProps={{
                            style: { fontSize: '13px', fontWeight: 400, color: "#B4B4B4" },
                        }}
                        className="input-field"
                        value={formData?.lastName}
                    />
                    <div style={{ display: 'flex', justifyContent: 'end', marginTop: '20px' }}>
                        <PrimaryButton
                            onClick={handleSubmit}
                            fullWidth={false}
                            isLoading={isLoading}>
                                Update
                        </PrimaryButton>
                    </div>
                </div>

            </div>
        </GridContainer>
    );
}

export default ChangeName;