import React, { useEffect, useState } from 'react';
import GridContainer from '../../components/molecules/GridContainer';
import { useSelector } from 'react-redux';
import { CircularProgress, Typography } from '@mui/material';
import './index.scss'
import CurriculumFiles from '../../components/atoms/CurriculumFiles';
import NoNotes from '../../components/atoms/NoNotes';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { colors } from 'assets/styles/colors';
import { FaAngleRight } from "react-icons/fa6";
import { IoMdClose } from "react-icons/io";
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import { filterIndexBySubjectName } from 'utils/commonUtils';
import { CreateButton } from '../../components/atoms/CustomButtons';
import CreateCurriculamModal from '../../components/atoms/CreateCurriculamModal';

interface Module {
    subjectId: string;
    sections?: any;
}

const Curriculum: React.FC = () => {

    const { subject } = useParams()
    const { classroomData } = useSelector((state: any) => state.classRoom) || {};
    const { isLastSubject, isCarriculamLoading } = classroomData
    const { isAdmin } = classroomData
    const [moduleData, setModuleData] = useState<any>(classroomData.curriculum?.length > 0 ? classroomData.curriculum?.[0].sections : []);
    const [selected, setSelected] = useState<number | null>(0)
    const [value, setValue] = React.useState(0);
    const [createType, setCreateType] = useState<any>('');
    const [editItem, setEditItem] = useState<any>(null);
    const curriculum = classroomData.curriculum?.length > 0 ? classroomData.curriculum : [];
    const subjectList = classroomData?.subjectList?.length > 0 ? classroomData.subjectList : [];

    useEffect(() => {
        onSelect(subjectList?.[value]?._id)
    }, [classroomData.curriculum]);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
        onSelect(subjectList?.[newValue]?._id)
    };

    function a11yProps(index: number) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const selectItem = (index: number) => {
        setSelected(index)
        setSelected(index === selected ? null : index)
    }

    useEffect(() => {
        onSelect(subjectList?.[value]?._id)
    }, [value]);

    useEffect(() => {
        filterIndexBySubjectName(subjectList, subject, setValue)
        onSelect(subjectList?.[0]?._id)
    }, []);

    function onSelect(id: any) {
        let temp_module: Module = {} as Module
        if (curriculum?.length > 0) {
            curriculum.map((item: any) => {
                if (item.subjectId == id) {
                    temp_module = item;
                }
            });
        }
        setModuleData(temp_module ?? []);
    }

    const onAddSessions = () => {
        setCreateType('Session')
        setEditItem(null)
    }

    const onEdit = () => {
        setCreateType('Session')
        if(selected!==null)
        setEditItem(moduleData?.sections?.[selected])
    }

    const onClose=()=>{
        setCreateType('')
        setEditItem(null)
    }


    const sections = moduleData?.sections ?? []
    return (
        <GridContainer
            pageTitle={'Curriculum'}
            right={selected !== null && <CurriculumFiles moduleData={moduleData} selected={selected} onEdit={onEdit} isOwned={isAdmin} data={sections[selected]} />}
            leftGridPartition={selected == null ? 1 : sections?.length == 0 ? 1 : 1 / 2}>
            <Tabs
                sx={{ '& .MuiTabs-indicator': { backgroundColor: colors?.primary } }}
                value={value}
                variant="scrollable"
                scrollButtons="auto"
                onChange={handleChange}
                aria-label="basic tabs example">
                {subjectList?.map((label: any, index: number) => (
                    <Tab wrapped label={label?.name} {...a11yProps(index)} />
                ))}
            </Tabs>
            {isAdmin && sections?.length !== 0 &&
                <div className='create-button-wrapper'>
                    <CreateButton onClick={onAddSessions} bgColor={'#575757'}>
                        Add Session
                    </CreateButton>
                </div>}
            {sections?.length == 0 ?
                <NoNotes onClick={onAddSessions} isOwned={isAdmin} type='Curriculum' /> :
                <div className='curriculum-wrapper'>
                    {isCarriculamLoading &&
                        <div style={{ marginBottom: '10px' }} className='loader-container'>
                            <CircularProgress size={15} sx={{ color: colors?.primary }} />
                        </div>}
                    <Typography marginBottom={'15px'} variant='h3'>{`${sections?.length} ${sections?.length == 1 ? 'Session' : 'Sessions'}`}</Typography>
                    {sections?.map((item: any, index: number) => (
                        <div>
                            <div onClick={() => selectItem(index)} className={`title ${selected == index ? 'selected' : ''}`}>
                                <Typography variant='body1'>{item?.title}</Typography>
                                {selected == index ?
                                    <IoMdClose className={`icon ${selected == index ? 'selected' : ''}`} /> :
                                    <FaAngleRight className={`icon ${selected == index ? 'selected' : ''}`} />}
                            </div>
                            {selected !== null &&
                                <div className={`${selected == index ? 'dropdown-menu' : 'hide-dropdown-menu'}`} >
                                    <CurriculumFiles data={sections[selected]} />
                                </div>}
                        </div>
                    ))}
                </div>}
            <CreateCurriculamModal
                open={createType == 'Session'}
                editItem={editItem}
                selected={selected}
                subject={subjectList?.[value]}
                module={moduleData}
                createType={createType}
                modalStyle={{ padding: '0px' }}
                handleClose={onClose} />
        </GridContainer>
    )
};

export default Curriculum;
