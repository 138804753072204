import React from 'react';
import { Backdrop, Box, Fade, Modal } from '@mui/material';
import { IoIosClose } from "react-icons/io";
import './index.scss'

interface Props {
    handleClose?: () => void;
    children: React.ReactNode;
    open: any;
    modalStyle?: any
    backdrop?: number;
    title?: string
}

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxHeight: '90vh',
    bgcolor: 'background.paper',
    border: '',
    boxShadow: '0px 3px 6px #0001',
    borderRadius: '25px',
    p: 2,
    outline: 'none'
};

const ModalView: React.FC<Props> = ({ handleClose, children, open, modalStyle, backdrop, title }) => {
    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                        sx: {
                            backgroundColor: `rgba(0, 0, 0, ${backdrop ?? '0.25'})`,
                        },
                    },
                }}>
                <Fade in={open}>
                    <Box sx={{ ...style, ...modalStyle }}>
                        <div>
                            {title &&
                                <div className='__header'>
                                    <h4>{title}</h4>
                                    <button onClick={handleClose}><IoIosClose /></button>
                                </div>}
                            {children}
                        </div>
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
};

export default ModalView;
