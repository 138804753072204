// src/components/CustomImagePicker.tsx

import React, { useState, useCallback, useEffect } from 'react';
import { Modal, Button, Slider } from '@mui/material';
import Cropper from 'react-easy-crop';
import { Area } from 'react-easy-crop/types';
import { getCroppedImg } from 'utils/cropImage';
import './index.scss'
import ModalView from '../ModalView';
import { MdOutlineDeleteOutline } from "react-icons/md";


interface CustomImagePickerProps {
    setSelectedImage: (image: File | null) => void;
    children: React.ReactNode;
    id?: any;
}

const CustomImagePicker: React.FC<CustomImagePickerProps> = ({ setSelectedImage, children, id }) => {
    const [selectedImage, setInternalSelectedImage] = useState<File | null>(null);
    const [preview, setPreview] = useState<string | null>(null);
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [rotation, setRotation] = useState(0);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area | null>(null);
    const [open, setOpen] = useState(false);
    const [croppedImage, setCroppedImage] = useState<string | null>(null);

    const onCropComplete = useCallback((croppedArea: Area, croppedAreaPixels: Area) => {
        setCroppedAreaPixels(croppedAreaPixels);
    }, []);

    const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            setInternalSelectedImage(file);
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreview(reader.result as string);
                setOpen(true);
            };
            reader.readAsDataURL(file);
            event.target.value = '';
        }
    };

    useEffect(() => {
        if (open) {
            setZoom(1);
            setRotation(0);
        }
    }, [open]);

    const handleModalClose = () => {
        setOpen(false);
    };

    const handleCropSave = async () => {
        if (!croppedAreaPixels || !preview) return;
        const croppedFile = await getCroppedImg(preview, croppedAreaPixels, rotation, selectedImage);
        setCroppedImage(URL.createObjectURL(croppedFile));
        setSelectedImage(croppedFile);
        setOpen(false);
    };

    return (
        <div className='__custom__image_picker'>
            <input
                type="file"
                accept="image/*"
                onChange={handleImageChange}
                style={{ display: 'none' }}
                id={`image-input-${id}`}
            />
            <div className='label_container'>
                <label style={{ cursor: 'pointer' }} htmlFor={`image-input-${id}`}>
                    {croppedImage ? (
                        <img src={croppedImage} alt="Cropped Preview" style={{ maxWidth: '300px' }} />
                    ) : (
                        <div className="__icon" onClick={() => document.getElementById('image-input')?.click()}>
                            {children}
                        </div>
                    )}
                </label>
                {croppedImage &&
                    <MdOutlineDeleteOutline onClick={() => {
                        setSelectedImage(null)
                        setCroppedImage(null)
                    }} size={'20px'} className='icon_delete' />}
            </div>

            <ModalView modalStyle={{ padding: 0 }} title="Crop Image" open={open} handleClose={handleModalClose}>
                <div style={{ padding: '15px' }}>
                    <div className='__custom__image_picker__modal'>
                        <Cropper
                            image={preview as string}
                            crop={crop}
                            zoom={zoom}
                            rotation={rotation}
                            aspect={1}
                            onCropChange={setCrop}
                            onCropComplete={onCropComplete}
                            onZoomChange={setZoom}
                            onRotationChange={setRotation}
                        />
                    </div>
                    <div className='__custom__image_picker__modal__footer'>
                        <div className='_slider_label'>zoom</div>
                        <Slider
                            value={zoom}
                            min={1}
                            max={3}
                            step={0.1}
                            aria-labelledby="Zoom"
                            onChange={(e, zoom) => setZoom(zoom as number)}
                        />
                        <div className='_slider_label'>Rotation</div>
                        <Slider
                            value={rotation}
                            min={0}
                            max={360}
                            step={1}
                            aria-labelledby="Rotation"
                            onChange={(e, rotation) => setRotation(rotation as number)}
                        />
                    </div>
                    <div className='__custom__image_picker__footer'>
                        <Button onClick={handleCropSave}>Save</Button>
                        <Button onClick={handleModalClose}>Cancel</Button>
                    </div>
                </div>
            </ModalView>
        </div>
    );
};

export default CustomImagePicker;
