import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import ClassRoomDetails from './ClassRoomDetails';
import Academic from './Academic';
import './ClassRoomDetails/index.scss'
import { SecondryButton } from '../atoms/CustomButtons';
import { getStatus } from 'utils/commonUtils';
import { useSelector } from 'react-redux';
import { colors } from 'assets/styles/colors';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
    item: any;
    onClick?:()=>void
}

interface BasicTabsProps {
    item?: any
    onClick?:()=>void
}

function CustomTabPanel(props: TabPanelProps) {

    const { children, value, index,onClick, item, ...other } = props;
    const { user } = useSelector((state: any) => state.user) || {};
    const classRooms = user?.classRooms

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <div className='tab-wrapper'>{children}</div>
                </Box>
            )}
            <div className='tab-button'>
                <SecondryButton
                    onMouseDown={()=>onClick?.()}
                    fullWidth={false}
                    isLoading={false}
                    text={getStatus(item, classRooms)} />
            </div>
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const BasicTabs: React.FC<BasicTabsProps> = ({ item,onClick }) => {

    const [value, setValue] = React.useState(0);
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };
    const labels = ['Classroom Details', 'Academic']

    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs 
          sx={{ '& .MuiTabs-indicator': { backgroundColor: colors?.primary } }}  value={value} onChange={handleChange} aria-label="basic tabs example">
                    {labels?.map((label, index) => (
                        <Tab label={label} {...a11yProps(index)} />
                    ))}
                </Tabs>
            </Box>
            <CustomTabPanel onClick={onClick} value={value} index={0} item={item}>
                <ClassRoomDetails item={item} />
            </CustomTabPanel>
            <CustomTabPanel onClick={onClick} value={value} index={1} item={item}>
                <Academic item={item} />
            </CustomTabPanel>
        </Box>
    );
}

export default BasicTabs
