import React, { useState } from 'react';
import './index.scss'
import class_logo from '../../../assets/images/class_logo.png'
import {
    KeyboardArrowRightOutlined,
    PeopleAltOutlined
} from '@mui/icons-material';
import { SecondryButton } from '../CustomButtons';
import { useDispatch, useSelector } from 'react-redux';
import { isAlreadyJoined, getStatus } from 'utils/commonUtils';
import { useClassMetadata, useJoinRoom, useSwichClass } from 'utils/customHooks';
import ClassSwitchModal from '../ClassSwitchModal';
import ClassRoomDetailModal from '../ClassRoomDetailModal';
import { setSelectedClassRoom } from '../../../redux/slices/setClassroom/classRoomSlice';
import { useSnackbar } from '../SnackbarProvider';
import { useNavigate } from 'react-router-dom';

const ClassRoomCard: React.FC<{ data: any }> = ({ data }) => {

    const { activeClassroom } = useSelector((state: any) => state.classRoom) || {};
    const { user } = useSelector((state: any) => state.user) || {};
    const classRooms = user?.classRooms
    const getClassMetadata = useClassMetadata();
    const dispatch = useDispatch();
    const { showSnackbar } = useSnackbar();
    const swichClass = useSwichClass()
    const [open, setOpen] = useState<boolean>(false);
    const [classRoom, setClassRoom] = useState<any>(null);
    const [classRoomDetail, setdetail] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    const handleOpen = (e: React.MouseEvent<HTMLElement>, item: any) => {
        if (getStatus(item, classRooms) === 'Pending') {
            showSnackbar('Waiting for Approval', 'warning')
        }
        else {
            if (activeClassroom?._id != item?._id)
                swichClass(item?._id, true)
            else
                showSnackbar('Already in this class room !', 'warning')
        }
    };

    const handleClose = () => {
        setIsOpen(false);
        setAnchorEl(null);
    };

    const openDetailModal = () => {
        setdetail(classRoom)
        getClassMetadata(classRoom?._id)

    }

    const handleDetailClose = () => {
        setdetail(null)
    }

    return (
        <div className='class-card'>
            <span className='class-count'>{data?.length} Classrooms</span>
            {data.map((item: any) => (
                <div onClick={(e) => handleOpen(e, item)} className='class-room-card' key={item.id}>
                    <img src={item?.logos?.small?.length > 0 ? item?.logos?.small : class_logo} className='class_logo' alt={item.name} />
                    <div className='class-room-content'>
                        <span className='name'>{item.name}
                        {activeClassroom?._id == item?._id ?<span className='active-class'>Active</span>:''}
                            {getStatus(item, classRooms) === 'Pending' ?<span className='requested-class'>Pending</span> : ''}

                        </span>
                        <span className='class-roomId'>#{item.classRoomId}</span>
                    </div>
                    <span className='class-room-student'>
                        <PeopleAltOutlined className='Drawer-icon' sx={{ fontSize: 18, marginRight: '10px' }} />
                        <span className='student'>{item?.members?.length === 1
                            ? '1 Student'
                            : `${item?.members?.length + 1} Students`}</span>
                    </span>
                    <KeyboardArrowRightOutlined className='Drawer-icon' sx={{ fontSize: 30 }} />
                </div>
            ))}
            <ClassSwitchModal item={classRoom} isOpen={isOpen} openDetailModal={openDetailModal} onClose={handleClose} anchorEl={anchorEl} />
            <ClassRoomDetailModal item={classRoomDetail} onClose={handleDetailClose} />
        </div>
    );
};

export default ClassRoomCard

const PreferenceCard: React.FC<{ data: any }> = ({ data }) => {
    const navigate = useNavigate();

    return (
        <div className='class-card'>
            {data.map((item: any) => (
                <div className='class-room-card preference-card' key={item.id}>
                    <div onClick={()=>navigate(item.route)} className='class-room-content'>
                        <span className='name'>{item.title}</span>
                        <span className='class-roomId'>{item.desc}</span>
                    </div>
                    <KeyboardArrowRightOutlined className='Drawer-icon' sx={{ fontSize: 30 }} />
                </div>
            ))}
        </div>
    );
};

interface Props {
    data: any,
    onClick: (clickedItem: any) => void
}

const ClassRoomListCard: React.FC<Props> = ({ data, onClick }) => {

    const joinRoom = useJoinRoom();
    const { user } = useSelector((state: any) => state.user) || {};
    const { activeClassroom } = useSelector((state: any) => state.classRoom) || {};
    const classRooms = user?.classRooms
    const dispatch = useDispatch()
    const swichClass = useSwichClass()
    const { showSnackbar } = useSnackbar();

    const onSubmitClick = (item: any) => {
        if (getStatus(item, classRooms) !== 'Pending') {
            dispatch(setSelectedClassRoom(item))
            if (getStatus(item, classRooms) === 'Joined') {
                swichClass(item?._id, true)
            }
            else {
                joinRoom(item?._id, item?.public)
            }
        }
        else{
            showSnackbar('Waiting for Classroom Admin Approval', 'info')
        }
    }

    return (
        <div className='class-card'>
            <span className='class-count'>{data?.length} Classrooms</span>
            {data.map((item: any) => {
                if (item?.active) {
                    return (
                        <div onClick={(event) => {
                            if (event.target === event.currentTarget) {
                                onClick(item);
                            }
                        }}
                            className='class-room-card' key={item.id}>
                            <img src={item?.logos?.small?.length > 0 ? item?.logos?.small : class_logo} className='class_logo' />
                            <div className='class-room-content'>
                                <span className='name list'>{item.name}{activeClassroom?._id == item?._id ?<span className='active-class'> Active </span>: ''}</span>
                                <span className='class-roomId list'>#{item.classRoomId}</span>
                            </div>
                            <SecondryButton
                            
                                fullWidth={false}
                                className={`class-room-list-card join-class-room-button ${getStatus(item, classRooms)?.toLowerCase()}`}
                                onMouseDown={() => onSubmitClick(item)}
                                isLoading={false}
                                text={getStatus(item, classRooms)} />
                        </div>
                    )
                }
                else return null;
            }
            )}
        </div>
    );
};

export { PreferenceCard, ClassRoomListCard }
