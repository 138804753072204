import React, { useState, useEffect } from 'react';
import GridContainer from '../../components/molecules/GridContainer';
import { useSelector } from 'react-redux';
import UserCard from '../../components/atoms/UserCard';
import { Typography } from '@mui/material';
import './index.scss'
import NoteFiles from '../../components/atoms/NoteFiles';
import NoNotes from '../../components/atoms/NoNotes';
import { useNavigate, useParams } from 'react-router-dom';
import _ from 'lodash';
import Tooltip from '@mui/material/Tooltip';
import { useFetchClassRoomItems } from 'utils/customHooks';
import { colors } from 'assets/styles/colors';
import CircularProgress from '@mui/material/CircularProgress';
import { CreateButton } from '../../components/atoms/CustomButtons';
import CreateNoteModal from '../../components/atoms/CreateNoteModal';
import ListActionMenu from '../../components/atoms/ListActionMenu';
import { fetchData } from "utils/fetch";
import { restAPIs } from "utils/restAPIs"
import { useSnackbar } from '../../components/atoms/SnackbarProvider';
import FilterList from '../../components/atoms/FilterList';
import ModalView from '../../components/atoms/ModalView';
import CommonDeleteModal from '../../components/atoms/CommonDeleteModal';


const Notes: React.FC = () => {

    const { activeClassroom, classroomData } = useSelector((state: any) => state.classRoom) || {};

    const { showSnackbar } = useSnackbar();
    const { isLastNote, isNoteLoading } = classroomData
    const { subject, refId } = useParams()
    const navigate = useNavigate();
    const [selected, setSelected] = useState<number>(0)
    const [selectedFilter, setSelectedFilter] = useState<any>(null)
    const [notes, setNotes] = useState<any>([]);
    const [page, setPage] = useState<number>(1)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isDeleteLoading, setIsDeleteLoading] = useState<boolean>(false)
    const [filter, setFilter] = useState<boolean>(false)
    const [isLastPage, setIsLastPage] = useState<boolean>()
    const [createType, setCreateType] = useState<any>('');
    const [editItem, setEditItem] = useState<any>(null);
    const [deleteModal, setDeleteModal] = useState<boolean>(false)
    const { fetchNotes, fetchMetaData } = useFetchClassRoomItems()

    const loadMore = async (initialPage?: boolean) => {
        if (!isLastPage || initialPage) {
            let params = {
                id: selectedFilter ? selectedFilter?._id : activeClassroom?._id,
                type: selectedFilter ? 'subject' : 'classroom',
                offset: initialPage ? 0 : page,
            };
            setIsLoading(true)
            const res = refId ? await fetchData(restAPIs.getNoteItem(refId),navigate) : await fetchData(restAPIs.getNotes(params),navigate)
            setIsLoading(false)
            if (res?.status === 200 && res?.data) {
                if (refId) {
                    setNotes([res.data])
                }
                else {
                    setNotes(initialPage ? res.data?.notes : notes.concat(res.data?.notes))
                }
                setIsLastPage(res.data.isLastPage)
                setPage(initialPage ? 1 : page + 1)
            }
        }
    }

    const onDelete = async () => {
        setDeleteModal(false)
        setIsDeleteLoading(true)
        const res = await fetchData(restAPIs.getNotesDelete(notes[selected]?._id),navigate)
        if (res.status == 200) {
            let temp = [...notes]
            temp.splice(selected, 1)
            setNotes(temp)
            fetchNotes()
            showSnackbar('Note deleted successfully', 'success')
            fetchMetaData()
            setIsDeleteLoading(false)
        }
        else {
            setIsDeleteLoading(false)
            showSnackbar('something went wrong !', 'error')
        }
    }
    const onEdit = () => {
        setEditItem(notes[selected])
        setCreateType('Note')
    }

    const onCreateNew = () => {
        setEditItem(null)
        setCreateType('Note')
    }

    const onFilterSet = () => {
        setFilter(false)
    }

    useEffect(() => {
        if (selectedFilter) {
            setPage(0)
            setNotes([])
            loadMore(true)
            setIsLastPage(false)
        }
        else {
            setNotes(classroomData.notesList)
        }
    }, [selectedFilter]);

    useEffect(() => {
        setIsLastPage(isLastNote)
    }, [isLastNote])

    useEffect(() => {
        if (!subject)
            setNotes(classroomData.notesList)
    }, [classroomData.notesList]);

    useEffect(() => {
        if (subject)
            setSelectedFilter({ _id: subject })
        else
            setSelectedFilter(null)
        if (refId) {
            setNotes([])
            loadMore(true)
        }
    }, [subject, refId]);

    const onBack = () => {
        navigate(-1)
    }

    return (
        <GridContainer
            loadMore={loadMore}
            pageTitle={!refId && 'Notes'}
            data={notes}
            onBack={refId ? onBack : undefined}
            loading={isLoading}
            page={page}
            right={<NoteFiles notes={notes[selected]} setNotes={setNotes} selected={selected} notesArray={notes} />}
            leftGridPartition={notes?.length == 0 && !isLoading ? 1 : 1 / 2}>
            {!refId &&
                <div className='create-button-wrapper'>
                    <CreateButton bgColor={'#575757'} onClick={onCreateNew}>
                        Create Note
                    </CreateButton>
                    {selectedFilter ?
                        <i className="fi fi-rr-filter" style={{ color: colors?.primary, fontSize: '18px', cursor: 'pointer' }} onClick={() => setFilter(!filter)}></i>
                        :
                        <i className="fi fi-rr-filter" style={{ fontSize: '18px', cursor: 'pointer' }} onClick={() => setFilter(!filter)}></i>}

                </div>}
            <ModalView title='Choose Subject' open={filter} handleClose={() => setFilter(false)} modalStyle={{ padding: 0 }}>
                <FilterList selected={selectedFilter} setSelected={setSelectedFilter} onFilterSet={onFilterSet} />
            </ModalView>
            {(isLoading || isNoteLoading) &&
                <div style={{ marginBottom: '10px' }} className='loader-container'>
                    <CircularProgress size={15} sx={{ color: colors?.primary }} />
                </div>}
            <div style={{ marginBottom: '20vh' }}>
                {notes?.map((item: any, index: number) => {
                    const fileCount = item?.files?.length
                    return (
                        <div onClick={() => setSelected(index)} className={`notes-container ${selected == index ? 'selected' : ''}`}>
                            <div className="title-note-container">
                                <text className='note-files-name'>{_.capitalize(item?.title)}</text>
                                {(index === selected && isDeleteLoading) ?
                                    <CircularProgress size={20} sx={{ color: colors?.primary }} /> :
                                    <ListActionMenu item={item} onEdit={onEdit} onDelete={() => setDeleteModal(true)} />}
                            </div>
                            <Typography variant='h6' fontWeight={400} fontSize={'12px'} style={{ wordWrap: 'break-word' }} key={index}>
                                {item.description}
                            </Typography>
                            <div className='notes-sub-container'>
                                {item?.subjectId?.name?.length > 0 &&
                                    <Tooltip title={item?.subjectId?.name}>
                                        <div className='notes-sub-name'>
                                            {item?.subjectId?.name}
                                        </div></Tooltip>}
                                <div className='notes-count-name'>
                                    {fileCount} {fileCount == 1 ? ' File' : ' Files'}
                                </div>
                            </div>
                            <div className="notes-user-card-wrapper">
                                <UserCard height={32} width={32} item={item} h1FontSize={'11px'} pFontSize={'10px'} />
                            </div>
                            <div className={`${selected == index ? 'dropdown-menu' : 'hide-dropdown-menu'}`} >
                                <NoteFiles notes={notes[selected]} />
                            </div>
                            <div className='line-separator' />
                        </div>
                    );
                })}
                <CreateNoteModal
                    open={createType == 'Note'}
                    editItem={editItem}
                    createType={createType}
                    modalStyle={{ padding: '0px' }}
                    handleClose={() => setCreateType('')} />
                {isLoading && !isLastPage && notes?.length > 0 &&
                    <div className='loader-container'>
                        <CircularProgress size={20} sx={{ color: colors?.primary }} />
                    </div>}
                {notes?.length == 0 && !isLoading &&
                    <NoNotes type={'Notes'} />}
                <CommonDeleteModal
                    open={deleteModal}
                    buttonLabel={'Yes, Delete'}
                    description={`This action cannot be undone. Are you sure you want to proceed?`}
                    title={'Delete File'}
                    handleClose={() => setDeleteModal(false)}
                    handleSubmit={() => onDelete()}
                />
            </div>
        </GridContainer>
    )
};

export default Notes
