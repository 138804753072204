import React, { useState, useCallback, useRef, useEffect } from 'react';
import {
    DialogContent,
    DialogActions,
    Grid,
    Badge
} from '@mui/material';
import { Area } from 'react-easy-crop/types';
import './index.scss'
import PrimaryButton, { CreateButton } from '../CustomButtons';
import { MdDelete } from 'react-icons/md';
import { colors } from 'assets/styles/colors';
import { IoIosClose } from "react-icons/io";
import { fetchData } from "utils/fetch";
import { restAPIs } from "utils/restAPIs"
import { useSnackbar } from '../SnackbarProvider';
import { useSelector } from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate } from 'react-router-dom';

interface Props {
    imageFile?: any;
    setImageFile: React.Dispatch<React.SetStateAction<any>>;
    editItem?: any;
    files?: any;
}

const aspectData = [
    { title: 'Crop', value: 0 },
    { title: 'Square', value: 1 },
    { title: '3:2', value: 3 / 2 },
    { title: '5:3', value: 5 / 3 },
    { title: '4:3', value: 4 / 3 },
    { title: '5:4', value: 5 / 4 },
    { title: '7:5', value: 7 / 5 },
    { title: '16:9', value: 16 / 9 },
    { title: '9:16', value: 9 / 16 },
]

const PhotoPicker: React.FC<Props> = ({ setImageFile, imageFile, editItem, files }) => {

    const { showSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const { classroomData, activeClassroom } = useSelector((state: any) => state.classRoom) || {};
    const [imageSrc, setImageSrc] = useState<string | null>(null);
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [rotation, setRotation] = useState(0);
    const [croppedArea, setCroppedArea] = useState<Area | null>(null);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [aspect, setAspect] = useState(aspectData[0]);
    const [selected, setSelected] = useState(0);
    const aspectOpen = Boolean(anchorEl);
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [croppedImageFile, setCroppedImageFile] = useState<File | null>(null);
    const [percentage, setPercentage] = useState(0);
    const [loadingIndex, setIsLoadingIndex] = useState<number | null>(null);

    const handleClose = () => {
        setCrop({ x: 0, y: 0 })
        setRotation(0)
        setZoom(1)
        setOpen(false);
        setImageSrc(null);
    };

    const handleCloseModal = () => {
        if (editItem) {
            let tempImage: any[] = [];
            editItem?.documents?.forEach(async (e: any) => {
                if (e.type?.startsWith('image/')) {
                    tempImage.push(e);
                }
            });
            setImageFile(tempImage)
        }
        else {
            setImageFile([])
        }
        setOpen(false);
        setImageSrc(null);
    };

    useEffect(() => {
        if (imageFile?.[selected]) {
            if (!imageFile?.[selected].url) {
                const reader = new FileReader();
                reader.readAsDataURL(imageFile?.[selected]);
                reader.onload = () => {
                    setImageSrc(reader.result as string);
                };
            }
        }
        else
            setImageSrc(null)
    }, [imageFile, selected]);

    const onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files;
        if (file) {
            const randomString = () => Math.random().toString(36).substring(2, 6)
            let temp = [...imageFile];
            Array.from(file).forEach((f) => {
                if (temp.length + files?.length < 10) {
                    const nameParts = f.name.split('.');
                    const extension = nameParts.pop();
                    const newName = `${nameParts.join('.')}_${randomString()}.${extension}`;
                    const newFile = new File([f], newName, { type: f.type });
                    temp.push(newFile);
                } else {
                    showSnackbar('Max limit of 10 files exceeded.', 'warning')
                }
            });
            setImageFile(temp);
        }
    };

    const handleDeleteFile = async (index: number) => {
        const updatedFiles = [...imageFile];
        const temp = updatedFiles.splice(index, 1);
        if (temp?.[0]?.url) {
            setIsLoadingIndex(index)
            const S3DeleteRes = await fetchData(restAPIs.getS3Delete(temp?.[0]?.url),navigate)
            setIsLoadingIndex(null)
            if (S3DeleteRes.status == 200 && S3DeleteRes.data) {
                setImageFile(updatedFiles);
            }
            else {
                showSnackbar('Something went wrong. Please try later', 'error')
            }
        }
        else {
            setImageFile(updatedFiles);
        }
    };

    return (
        <div>
            <Badge
                sx={{
                    '& .MuiBadge-badge': {
                        color: '#fff',
                        fontWeight: 400
                    },
                }}
                onClick={() => fileInputRef.current?.click()}
                badgeContent={imageFile?.length ? imageFile?.length > 9 ? '9+' : imageFile?.length : 0}
                color="primary">
                <i className="fi fi-rr-picture"></i>
                <input
                    type="file"
                    hidden
                    multiple
                    ref={fileInputRef}
                    accept="image/*"
                    onChange={onFileChange}
                    max={10}
                />
            </Badge>
        </div>
    );
};

export default PhotoPicker;
