import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import store from '../redux/store/store';
import { useDispatch } from 'react-redux';
import { clearUserDetails } from '../redux/slices/setUser/userSlice';
import { performLogout } from './customHooks';
import { useNavigate } from 'react-router-dom';



interface Request {
  method: string;
  endpoint: string;
  body?: Record<string, any>;
}

type NavigateFunction = (path: string) => void;
export const fetchData = async (request: Request,navigate: NavigateFunction): Promise<any> => {
  const userState = store.getState().user;
  const { method, endpoint, body } = request;
  let sessiontoken = localStorage.getItem('sessiontoken');
  if (!sessiontoken){
      // @ts-ignore
    if(userState?.user?.token)
        // @ts-ignore
    sessiontoken=userState?.user?.token
      // @ts-ignore
    localStorage.setItem('sessiontoken', userState?.user?.token);
    localStorage.setItem('user',JSON.stringify(userState?.user));
  }
  const requestModel: AxiosRequestConfig = {
    method,
    url: endpoint,
    data: body
  };
  if (sessiontoken)
    requestModel.headers = {
      Authorization: sessiontoken
    };
  try {
    const response: AxiosResponse<any> = await axios.request(requestModel);
    console.log('response', response.status);
   
    return response.data;
  } catch (e:any) {
    if (e?.response?.status === 401) {
      console.log('entered')
      localStorage.clear();
      navigate('/login/reset'); // Navigate to the login page
    }
    return {
      status: e.response?.status,
      data: e.response?.data
    }
  }
};