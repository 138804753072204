import { colors } from 'assets/styles/colors';
import React, { useState } from 'react';
import { MdOutlineFileDownload, MdVisibility } from "react-icons/md";
import { FiEye } from "react-icons/fi";

var FileSaver = require('file-saver');


interface Note {
    url: string;
    signedUrl?: string;
    uri: string;
}

interface DownloadFileProps {
    note: Note;
    filename: string; // Adjust this as necessary for your app
}

const style = { minWidth: '40px', fontSize:'20px', marginLeft:'10px' }

const DownloadFileComponent: React.FC<DownloadFileProps> = ({ note, filename }) => {
    const [fileStatus, setFileStatus] = useState<number>(0); // 0: not started, 1: in progress, 2: completed

    const downloadFileProgress = (event: ProgressEvent) => {
        if (event.lengthComputable) {
            const percentComplete = (event.loaded / event.total) * 100;
        }
    };

    const downloadFileBegin = () => {
        console.log('');
    };

    const downloadFile = async () => {
        const uri = note.url;
        const res = note.signedUrl ? note.signedUrl : note.uri;

        if (fileStatus !== 1) {
            setFileStatus(1);
            downloadFileBegin();
            try {
                FileSaver.saveAs(res, filename)
                const total = 0;
                let loaded = 0;
                const chunkSize = total / 100; 
                const interval = setInterval(() => {
                    loaded += chunkSize;
                    downloadFileProgress({
                        lengthComputable: true,
                        loaded: Math.min(loaded, total),
                        total,
                    } as ProgressEvent);
                    if (loaded >= total) {
                        clearInterval(interval);
                        setFileStatus(2);
                    }
                }, 100);
            } catch (error) {
                setFileStatus(0);
            }
        }
    };

    return (
        <div>
            <i className="fi fi-rr-eye" onClick={downloadFile} style={style} color={colors?.normal} ></i>
        </div>
    );
};

export default DownloadFileComponent;
