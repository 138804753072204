import { useSelector } from "react-redux";
import { CheckCircle } from '@mui/icons-material';
import './index.scss'
import { colors } from "assets/styles/colors";

interface ItemProps {
  item: any;
}

const Academic: React.FC<ItemProps> = () => {

  const classMetaData = useSelector(
    (state: any) => state.classRoom?.SelectedClassMetadata
  );
  const temp_Value = 'Loading . . .'
  const data = [
    { 'Note': classMetaData ? classMetaData?.notes : temp_Value },
    { 'Document': classMetaData ? classMetaData?.docs : temp_Value },
    { 'Timetable': classMetaData ? classMetaData?.timeTablePresent ? 'Available' : 'Unavailable' : temp_Value },
    { 'curriculum': classMetaData ? classMetaData?.curriculums > 0 ? 'Available' : 'Unavailable' : temp_Value }
  ]

  return (
    <div className='classroom-details'>
      {data?.map((item, index) => {
        return (
          <div className='classroom-details-wrapper'>
            {Object.entries(item).map(([key, value]) => (
              <div className='academic-details-card'>
                <span className='academic-key-text'>{key}</span>
                <span className='academic-value-text'>{value}
                  {(item?.Timetable == 'Available' || item?.curriculum == 'Available') &&
                    <span><CheckCircle className='Drawer-icon' sx={{ fontSize: 18, marginLeft: '5px', color: colors?.primary }} />
                    </span>}</span>
              </div>
            ))}
            {index !== data?.length - 1 &&
              <div className='line-separator' />}
          </div>

        )
      })}
    </div>
  );
};

export default Academic;
