import CommentlModal from '../../components/molecules/CommentModal';
import GridContainer from '../../components/molecules/GridContainer';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useCustomSWR } from 'utils/customHooks';
import { restAPIs } from 'utils/restAPIs';
import { CircularProgress } from '@mui/material';
import CreatePostEditModal from '../../components/atoms/CreatePostEditModal';
import CommonDeleteModal from '../../components/atoms/CommonDeleteModal';
import { useSnackbar } from '../../components/atoms/SnackbarProvider';
import { fetchData } from 'utils/fetch';
import NoNotes from '../../components/atoms/NoNotes';
import { useSelector } from 'react-redux';

const FeedDetails: React.FC = () => {

    const { refId = '' } = useParams()
    const { showSnackbar } = useSnackbar();
    const { user } = useSelector((state: any) => state.user) || {};
    const navigate = useNavigate();
    const [editOpen, setEditOpen] = useState<boolean>(false);
    const [deleteModal, setDeleteModal] = useState<boolean>(false)
    const [feed, setFeed] = useState<any>([])
    const [isDeleteLoading, setLoading] = useState(false);

    const { data, isLoading, isError, mutate } = useCustomSWR(restAPIs.getFeedDetails(refId));

    const post = feed?.[0]
    const title = post ? `${post?.userId?.firstName}'s Post` : 'Post'
    const onEdit = () => {
        setEditOpen(true)
    }

    useEffect(() => {
        if (feed?.length==0) {
            setFeed(data?.status == 200 ? [data?.data] : []);
        }
    }, [data]);

    const onDeleteItem = () => {
        setDeleteModal(true)
    }

    const onDelete = async () => {
        setLoading(true)
        const res = await fetchData(restAPIs.getDeletePost(post?._id),navigate)
        if (res?.data && res.status == 200) {
            let temp = [...feed]
            temp.splice(0, 1);
            setFeed([...temp])
            showSnackbar(`Post deleted successfully`, 'success')
            setDeleteModal(false)
            mutate()
            navigate(-1)
        }
        else {
            showSnackbar(`Something went wrong`, 'error')
        }
        setLoading(false)
    }

    return (
        <GridContainer
            backButton={true}
            pageTitle={title}
            onEdit={onEdit}
            onDelete={onDeleteItem}
            optionsVisible={post?.userId?._id == user?._id }
            leftGridPartition={1}>
            {!isLoading ?
                <CommentlModal fromPostDetails commentCount={post?.commentCount} item={post} /> :
                <div style={{ marginTop: '20px' }}><CircularProgress size={'20px'} color="primary" /></div>}
            <CreatePostEditModal
                open={editOpen}
                selectedIndex={0}
                feed={feed}
                setFeed={setFeed}
                editItem={post}
                handleClose={() => setEditOpen(false)}
                handleOpen={() => setEditOpen(true)} />
                 <CommonDeleteModal
                open={deleteModal}
                isLoading={isDeleteLoading}
                description={`This action cannot be undone. Are you sure you want to proceed?`}
                title={'Delete Post'}
                handleClose={() => setDeleteModal(false)}
                handleSubmit={() => onDelete()}
            />
{feed?.length == 0 && !isLoading &&
                    <NoNotes type={'Post unavailable'} />}
        </GridContainer>
    );
};

export default FeedDetails;
