import './index.scss'
import PrimaryButton, { SecondryButton } from '../CustomButtons';
import { useSwichClass } from 'utils/customHooks';
import PopoverView from '../PopoverView';
import { IoIosClose } from "react-icons/io";
import { useSelector } from 'react-redux';

interface ChildProps {
    item: any;
    isOpen: boolean;
    onClose?: () => void
    openDetailModal?: any
    anchorEl: HTMLElement | null;
}

const ClassSwitchModal: React.FC<ChildProps> = ({ item, isOpen, onClose, openDetailModal, anchorEl }) => {
    const { activeClassroom } = useSelector((state: any) => state.classRoom) || {};

    const swichClass = useSwichClass()
    return (
        <PopoverView
            open={isOpen} handleClose={onClose} anchorEl={anchorEl}>
            <div className='create-class-switch-modal'>
                <div className='__header'>
                    <h4 className='class-switch-text'>{item?.name}</h4>
                    <button onClick={onClose}><IoIosClose /></button>
                </div>
                <div className='class-switch-container'>
                    <SecondryButton
                        onClick={() => openDetailModal()}
                        text='View Details' />
                    <PrimaryButton
                        disabled={activeClassroom?._id == item?._id}
                        onClick={() => swichClass(item?._id)}
                        text={activeClassroom?._id == item?._id ? 'Already in this Class' : 'Switch To This Classroom'} />
                </div>
            </div>
        </PopoverView>
    );
};

export default ClassSwitchModal;

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 300,
    bgcolor: 'background.paper',
    border: '.5px solid #000',
    boxShadow: 24,
    height: '200px',
    borderRadius: '10px',
};

