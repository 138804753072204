import React, { useEffect, Suspense } from 'react';
import { Routes, BrowserRouter as Router, Route, Navigate, Outlet, useNavigate, useLocation } from 'react-router-dom';
import { Grid, Paper, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import Notes from '../pages/Notes'
import Documents from '../pages/Documents'
import Subjects from '../pages/Subjects'
import Curriculum from '../pages/Curriculum'
import Events from '../pages/Events'
import Settings from '../pages/Settings'
import SideMenu from '../components/molecules/SideMenu'
import Feed from 'pages/Feed';
import Account from 'pages/Account';
import Home from 'pages/Home';
import Timetable from 'pages/Timetable';
import ReportAProblem from 'pages/ReportAProblem';
import Classmates from 'pages/Classmates';
import ChangeName from 'pages/ChangeName';
import MyActivities from 'pages/MyActivities';
import NoteDetails from 'pages/NoteDetails'
import DocumentDetails from 'pages/DocumentDetails'
import FeedDetails from 'pages/FeedDetails'
import ClassName from 'pages/ClassName'
import ClassId from 'pages/ClassId'
import Template from 'pages/Template'
import SetPrivacy from 'pages/SetPrivacy'

const Login = React.lazy(() => import('../pages/Login'));
const FindClassRoom = React.lazy(() => import('../pages/FindClassRoom'));
const ClassRoomFetch = React.lazy(() => import('../pages/ClassRoomFetch'));

const AppRouter = () => {
    const { user } = useSelector((state: any) => state.user) || {};
    const { activeClassroom } = useSelector((state: any) => state.classRoom) || {};

    const PrivateRoutes = () => {
        return (
            user ? activeClassroom ? <Outlet /> : <Navigate to='/home' /> : <Navigate to='/login' />
        )
    }

    return (
        <div>
            <Router>
                <Grid container spacing={0} style={{ height: '100vh' }}>
                    <Suspense fallback={<div></div>}>
                        {user &&
                            <SideMenu />}
                        <Routes>
                            <Route element={<PrivateRoutes />}>
                                <Route path="/myactivities" element={<MyActivities />} />
                                <Route path="/feed" element={<Feed />} />
                                <Route path="/feed/ref/:refId" element={<FeedDetails />} />
                                <Route path="*" element={<Feed />} />
                                <Route path="/notes/:subject" element={<Notes />} />
                                <Route path="/notes/ref/:refId" element={<NoteDetails />} />
                                <Route path="/notes" element={<Notes />} />
                                <Route path="/documents/:subject" element={<Documents />} />
                                <Route path="/documents" element={<Documents />} />
                                <Route path="/documents/ref/:refId" element={<DocumentDetails />} />
                                <Route path="/subjects" element={<Subjects />} />
                                <Route path="/curriculum" element={<Curriculum />} />
                                <Route path="/curriculum/:subject" element={<Curriculum />} />
                                <Route path="/events" element={<Events />} />
                                <Route path="/timetable" element={<Timetable />} />
                                <Route path="/report" element={<ReportAProblem />} />
                                <Route path="/classmates" element={<Classmates />} />
                                <Route path="/change-name" element={<ChangeName />} />
                                <Route path="/class-name" element={<ClassName />} />
                                <Route path="/class-id/:name" element={<ClassId />} />
                                <Route path="/template/:name/:id" element={<Template />} />
                            </Route>
                            {user &&
                                <>
                                    <Route path="/home" element={<Home />} />
                                    <Route path="/settings" element={<Settings />} />
                                    <Route path="/classroomfetch/:classRoomId/:type" element={<ClassRoomFetch />} />
                                    <Route path="/set-privacy" element={<SetPrivacy />} />
                                    <Route path="/account" element={<Account />} />
                                    <Route path="/findclassroom" element={<FindClassRoom />} />
                                </>}
                            <Route path="/login" element={<Login />} />
                            <Route path="/login/:reset" element={<Login />} />
                            <Route path="/register/:typeParams" element={<Login />} />
                        </Routes>
                    </Suspense>
                </Grid>
            </Router>
        </div>
    );
};

export default AppRouter;
